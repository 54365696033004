import React, { Component } from "react";
import '../style.css';
import api from "../../../services/api";
import { withRouter } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';

import "react-day-picker/lib/style.css";

var dateFormat = require('dateformat');

class Agendamentos extends Component {
    state = {
        open: true,
        fullScreen: false,
        api_token: localStorage.getItem('api_token'),
        username: localStorage.getItem('name'),
        age: '',
        vehicles: [],
        openSelect: false,
        openSelectVehicle: false,
        drivers: [],
        selectedOption: null,
        dataAtual: dateFormat(new Date().toLocaleDateString("en-us"), "yyyy-mm-dd"),
        formValidation: false,

        //DATA STATES
        id_user: '',
        id_vehicle: '',
        reason: '',
        date: '',
        hour_initial: dateFormat(new Date().setHours(0, 0, 0)),
        hour_end: dateFormat(new Date().setHours(0, 0, 0)),

        //BUTTON LOADING
        setSuccess: false,
        setLoading: false,

        setLoadingCondutor: false,
        setLoadingVehicles: false,
        setLoadingVehiclesWarning: true,

        newscheduling: false,
        dataPassada: false,

        admin: false

    }

    componentDidMount = async e => {
        this.setState({ date: this.state.dataAtual, setLoadingCondutor: true });
        setTimeout(() => {
            console.clear();
        }, 100);
        try {
            await api.get("/user/drivers?api_token=" + this.state.api_token)
            .then( res => {
                if(res.data.error == true){
                    toast.warning('🚗 Algo deu errado, tente atualizar a página!');
                    this.setState({ setLoadingCondutor: false });
                    return false;
                }
                this.setState({ drivers: res.data.drivers, setLoadingCondutor: false});
                {res.data.drivers.map((drivers, key) =>
                    this.state.username.toLowerCase() === drivers.name.toLowerCase() ? 
                        this.setState({ id_user: drivers.id})
                    : null
                )}
            });
            api.get('/user/info?api_token=' + this.state.api_token).then( res => {
                if(res.data.user_info.type_user.toLowerCase() === 'administrator') this.setState({ admin: true });
            });

        } catch (error) {
            console.error(error);
        }
    }

    handleSend = async e => {
        const { id_user, id_vehicle, reason, date, dataPassada, admin } = this.state;
        const hour_initial = dateFormat(this.state.hour_initial, 'HH:MM:ss');
        const hour_end = dateFormat(this.state.hour_end, 'HH:MM:ss');
        if( !id_user || !id_vehicle){
            this.setState({ formValidation: true});
            return false;
        }
        
        if( dataPassada === true && admin === false) {
            return false;
        }
        
        let reasonModified = null;
        if(!reason) {
            reasonModified = 'O condutor preferiu não especificar o motivo';
        }else {
            reasonModified = reason;
        }

        this.setState({ setSuccess: false, setLoading: true, formValidation: false, dataPassada: false});
        try {
            await api.post("/vehicles-reservation?api_token=" + this.state.api_token,
            { id_user, id_vehicle, reason: reasonModified, date, hour_initial, hour_end })
            .then(res => {
                if(res.data.error == true){
                    toast.warning('🚗 Algo deu errado, tente novamente!');
                    this.setState({ setLoading: false });
                    return false;
                }
                toast.success('🚗 Reserva realizada com sucesso!😉');
                this.setState({ setLoading: false, newscheduling: true });
                this.handleClose();
            })
            .catch(e => {
                toast.error('Algo deu errado com a conexão😕 Não foi possível realizar o agendamento!');
                this.setState({ setLoading: false });
              })
        } catch (err) {
            this.setState({
                setSuccess: false, setLoading: false,
            });
            toast.error('Algo deu errado!😕 Tente novamente em alguns minutos!');
        }
    }

    //MODAL EVENTS
    handleClickOpen = async e => { this.setState(state => ({ open: !state.open })); }
    handleClose = async e => {
        this.setState(state => ({ open: !state.open }));
        if(this.state.newscheduling === true){
            this.props.handleAttReservations();
        }
    }

    //DRIVER SELECT EVENTS
    handleChangeSelect = event => { this.setState({ id_user : event.target.value });};
    handleCloseSelect = () => { this.setState({ openSelect: false }); };
    handleOpenSelect = () => { this.setState({ openSelect: true }); };

    //VEHICLE SELECT EVENTS
    handleChangeSelectVehicle = event => { this.setState({ id_vehicle : event.target.value }); };
    handleCloseSelectVehicle = () => { this.setState({ openSelectVehicle: false }); };
    handleOpenSelectVehicle = () => { this.setState({ openSelectVehicle: true }); };

    handleInitialHourChange = e => { this.setState({ hour_initial: e, initialhour: e }); this.handleFinalHourChange(this.state.hour_end); }

    handleFinalHourChange = e => {
        this.setState({ hour_end: e, endhour: e, setLoadingVehicles: true });

        setTimeout(() => {   
            try {
                api.post("/vehicles-available?api_token=" + this.state.api_token, {date: this.state.date, hour_initial: dateFormat(this.state.hour_initial, 'HH:MM:ss'), hour_end: dateFormat(this.state.hour_end, 'HH:MM:ss')})
                .then(res => {
                    if(res.data.error == true){
                        toast.warning('🚗 Algo deu errado, tente atualizar a página!');
                        this.setState({ setLoadingVehicles: false });
                        return false;
                    }
                    this.setState({ vehicles: res.data.vehicles_availables, setLoadingVehicles: false, setLoadingVehiclesWarning: false });
                });
            } catch ( err ){
                return false;
            }
        }, 50);
    }

    handleDateChange = (e) => {
        var dateArr = e.target.value.split("-");

        var dateToCompare = new Date(parseInt(dateArr[0]), parseInt(dateArr[1])-1, parseInt(dateArr[2]));

        // Date object for today
        var today = new Date();

        if(dateToCompare < today && e.target.value !== this.state.dataAtual) {
            this.setState({ dataPassada: true });
        }else {
            this.setState({ dataPassada: false });
        }
        this.setState({ date: e.target.value });
    }

    render(){
        return (
            <div id="reservationmodal">
                {/*
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
                <ToastContainer />
                */}

                <Dialog
                    fullScreen={this.state.fullScreen}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={false}
                    >
                    <DialogTitle id="responsive-dialog-title">{"Criar novo agendamento"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                            <form autoComplete="off" className="formSchedule">
                                <div className="select MuiFormControl-root">
                                    <select disabled={this.state.setLoadingCondutor === true ? 'disabled' : ''} className="select-text" name="id_user" required onChange={this.handleChangeSelect}>
                                        <option disabled selected value=""></option>
                                        {this.state.drivers.map((drivers, key) =>
                                            <option key={key} value={drivers.id} selected={this.state.username.toLowerCase() === drivers.name.toLowerCase() ? true : false}> {drivers.name} </option> 
                                        )}
                                    </select>
                                    {this.state.setLoadingCondutor === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                                    <span className="select-highlight"></span>
                                    <span className="select-bar"></span>
                                    <label className="select-label">Condutores disponíveis</label>
                                </div>
                                <TextField className="field" onChange={e => this.setState({ reason: e.target.value })} id="standard-full-width" label="Motivo" placeholder="Escreva o motivo da sua saída" InputLabelProps={{shrink: true,}}/>
                                <TextField onChange={(e) => this.handleDateChange(e)} id="date" label="Data do agendamento" type="date" defaultValue={this.state.dataAtual} InputLabelProps={{shrink: true,}}/>

                                <div className="inline-picker">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardTimePicker
                                            margin="normal"
                                            id="mui-pickers-time"
                                            label="Hora saída"
                                            format="HH:mm"
                                            value={this.state.hour_initial}
                                            onChange={(e) => this.handleInitialHourChange(e)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            />
                                            <KeyboardTimePicker
                                            margin="normal"
                                            id="mui-pickers-time"
                                            label="Hora chegada"
                                            format="HH:mm"
                                            value={this.state.hour_end}
                                            onChange={(e) => this.handleFinalHourChange(e)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </div>
                                
                                <div className="select MuiFormControl-root" required onChange={this.handleChangeSelectVehicle}>
                                    <select disabled={this.state.setLoadingVehiclesWarning === true ? 'disabled' : ''} className={["select-text"].join(' ')} required>
                                        <option disabled selected value=""></option>
                                        {this.state.vehicles.map((vehicles, key) =>
                                            <option key={key} value={vehicles.id}>{vehicles.name}</option> 
                                        )}
                                    </select>
                                    {this.state.setLoadingVehicles === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                                    <span className="select-highlight"></span>
                                    <span className="select-bar"></span>
                                    <label className={["select-label", this.state.setLoadingVehiclesWarning === true ? 'disabled' : 'active'].join(' ')}>Veículos disponíveis</label>
                                    <label className={["select-label", this.state.setLoadingVehiclesWarning === true ? 'active' : 'disabled'].join(' ')}>*Selecione um horário para vizualizar os veículos disponíveis</label>
                                </div>
                            </form>
                    </DialogContent>

                    {this.state.dataPassada === true && this.state.admin === false ? 
                        <DialogActions>
                            <span className="spanerror">Ah Claro, eu sei que você quer agendar para uma data passada. Mas isso não é possível se você não for um administrador. :/</span>
                        </DialogActions>
                    : null}

                    {this.state.formValidation === true ? 
                        <DialogActions>
                            <span className="spanerror">Preencha corretamente todos os campos</span>
                        </DialogActions>
                    : null}

                    <DialogActions>
                        <div className="wrapper">
                            <Fab variant="extended" size="large" color="primary" aria-label="Add" onClick={this.handleSend} className={["btn-send",this.state.setSuccess === true ? 'buttonSuccess' : ''].join(" ")} disabled={this.state.setLoading}>Reservar</Fab>
                            {this.state.setLoading === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                        </div>
                        <Button onClick={this.handleClose} color="primary">
                        Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withRouter(Agendamentos);