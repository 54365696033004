import React, { Component } from "react";
import '../forms.css';
import api from "../../../services/api";
import { withRouter } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import DeleteIcon from '@material-ui/icons/Delete';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';

import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import CircularProgress from '@material-ui/core/CircularProgress';

import { toast } from 'react-toastify';

//var dateFormat = require('dateformat');

class Vehicle extends Component {
    state = {
        open: true,
        fullScreen: false,
        api_token: localStorage.getItem('api_token'),
        username: localStorage.getItem('name'),
        vehicles: [],
        progress: true,

        openConfirm: false,
        id: '',

        //BUTTON LOADING
        setSuccess: false,
        setLoading: false,
    }

    handleAttVehicles = async e => {
        try {
            await api.get("/vehicles?api_token=" + this.state.api_token)
            .then(res => {
                if(res.data.error == true){
                    toast.warning('🚗 Algo deu errado, tente atualizar a página!');
                    this.setState({ progress: false });
                    return false;
                }
                this.setState({ progress: false ,vehicles: res.data.vehicles});
            });
        } catch (error) {
            toast.error('Algo deu errado!😕 Tente novamente em alguns minutos!');
        }
    }

    componentDidMount = async e => {
        this.handleAttVehicles();
    }


    //CRUD EVENTS
    handleDelete = e => {
        this.setState({ setSuccess: false, setLoading: true });
        var config = {
            _method: 'DELETE',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            withCredentials: false
        }
        try {
            api.post(`/vehicles/${e}?api_token=`+ this.state.api_token, config)
            .then ( res => {
                if(res.data.error == true){
                    toast.warning('🚗 Algo deu errado, tente novamente!');
                    this.setState({ setLoading: false });
                    return false;
                }
                this.handleAttVehicles();
                toast.success('🚗 Veículo deletado com sucesso!😉');
                this.setState(state => ({
                    openConfirm: !state.openConfirm
                }));
                this.setState({ setSuccess: true, setLoading: false});
            })
        }catch (err){
            toast.error('Algo deu errado!😕 Tente novamente em alguns minutos!');
            this.setState({
                setSuccess: false, setLoading: true,
            });
            this.setState(state => ({
                openConfirm: !state.openConfirm
            }));
        }
    }

    //MODAL EVENTS
    handleClickOpen = async e => {
        this.setState(state => ({
            open: !state.open
        }));
    }
    
    handleClose = async e => {
        this.setState(state => ({
            open: !state.open
        }));
    }

    handleModalConfirm = async e => {
        this.setState(state => ({
            openConfirm: !state.openConfirm
        }));
        this.setState({ id: e });
    }

    render(){
        return (
            <div id="vehicles">
                <Dialog
                    fullScreen={this.state.fullScreen}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                    >
                    <div className={["modalConfirm", this.state.openConfirm === true ? "active" : "disable"].join(" ")}>
                        <WarningIcon></WarningIcon>
                        <h2>Deseja realmente deletar este veículo?</h2>
                        <div className="controls">
                            <div className="wrapper">
                                <Button variant="contained" onClick={() => this.handleModalConfirm(null)} disabled={this.state.setLoading}>
                                    Não, Cancelar
                                </Button>
                            </div>
                            <div className="wrapper">
                                <Button variant="contained" color="primary" onClick={() => this.handleDelete(this.state.id)} className="btn-send" disabled={this.state.setLoading}>
                                    Sim, Deletar veículo
                                </Button>
                                {this.state.setLoading === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                            </div>
                        </div>
                    </div>
                    <DialogTitle id="responsive-dialog-title">{"Veículos cadastrados"}</DialogTitle>
                    <DialogContent>
                            <List>
                                {this.state.vehicles.map((vehicles, key) => (
                                    <div className="listItem" key={key}>
                                        <DirectionsCar />
                                        <p>{vehicles.name}</p>
                                        <p className="plate">{vehicles.license_plate}</p>
                                        <div className="list-actions">
                                            {/*<IconButton value={vehicles.id} > <EditIcon color="primary" /> </IconButton>*/}
                                            {/*<Tooltip TransitionComponent={Zoom} title="Deletar veículo" placement="left">*/}
                                                <IconButton disabled value={vehicles.id} onClick={() => this.handleModalConfirm(vehicles.id)} > <DeleteIcon color="error" /> </IconButton>
                                            {/*</Tooltip>*/}
                                        </div>
                                    </div>
                                ))}
                            </List>
                            <div className={["list-progress", this.state.progress === true ? 'active' : 'disable'].join(' ')}>
                                <CircularProgress />
                            </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default withRouter(Vehicle);