import api from './api';

const validation = async e => {
    var isAuthenticated = false;

    try {
        const token = localStorage.getItem('api_token');
        await api.get('/user/info?api_token=' + token)
        .then(function (success) {
            if(success.data.error === false){
                return isAuthenticated = true;
            }else {
                return isAuthenticated = false;
            }
        }).catch(function (erno) {
            return isAuthenticated = false;
        });
        return isAuthenticated;
    } catch (e) {
        isAuthenticated = false;
    } 
   
   return isAuthenticated;

}

export default validation;