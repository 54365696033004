import React, { Component } from "react";
import '../forms.css';
import api from "../../../services/api";
import { withRouter } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import PersonIcon from '@material-ui/icons/Person';
import WarningIcon from '@material-ui/icons/Warning';

import { toast } from 'react-toastify';

import CircularProgress from '@material-ui/core/CircularProgress';

//var dateFormat = require('dateformat');

class Users extends Component {
    constructor(props) {
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
    }    

    state = {
        open: true,
        fullScreen: false,
        api_token: localStorage.getItem('api_token'),
        username: localStorage.getItem('name'),
        users: [],
        progress: true,

        openConfirm: false,
        id: '',
        filter: '',
        checked: false,
        filterByDrivers: false,
    }

    handleAttUsers = async e => {
        try {
            await api.get("/user?api_token=" + this.state.api_token)
            .then(res => {
                this.setState({progress: false, users: res.data.users});
            });
        } catch (error) {
            toast.error('Algo deu errado!😕 Tente novamente em alguns minutos!');
        }
    }

    componentDidMount = async e => {
        this.handleAttUsers();
    }

    //MODAL EVENTS
    handleClickOpen = async e => {
        this.setState(state => ({
            open: !state.open
        }));
    }
    
    handleClose = async e => {
        this.setState(state => ({
            open: !state.open
        }));
    }

    //LIST CRUD EVENTS
    handleDelete = e => {
        try {
            api.delete(`/vehicles/${e}?api_token=`+ this.state.api_token)
            .then ( res => {
                this.handleAttUsers();
                toast.success('🚗 Veículo deletado com sucesso!😉');
                this.setState(state => ({
                    open: !state.open
                }));
            })
        }catch (err){
            toast.error('Algo deu errado!😕 Tente novamente em alguns minutos!');
        }
    }

    handleModalConfirm = async e => {
        this.setState(state => ({
            openConfirm: !state.openConfirm
        }));
        this.setState({ id: e });
    }

    handleChange = async e => {
        this.setState(state => ({
            filterByDrivers: !state.filterByDrivers
        }));
      }

    render(){
        return (
            <div id="users">
                <Dialog
                    fullScreen={this.state.fullScreen}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                    >
                    <div className={["modalConfirm", this.state.openConfirm === true ? "active" : "disable"].join(" ")}>
                        <WarningIcon></WarningIcon>
                        <h2>Deseja realmente deletar este veículo?</h2>
                        <div className="controls">
                            <Button variant="contained" onClick={() => this.handleModalConfirm(null)}>
                                Não, Cancelar
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => this.handleDelete(this.state.id)}>
                                Sim, Deletar veículo
                            </Button>
                        </div>
                    </div>
                    <DialogTitle id="responsive-dialog-title">{"Usuários cadastrados"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>Todos os usuários</Grid>
                                <Grid item>
                                    <Switch
                                        checked={this.state.filterByDrivers}
                                        onChange={this.handleChange}
                                        value="checkedA"
                                    />
                                </Grid>
                                <Grid item>Apenas condutores</Grid>
                            </Grid>
                        </DialogContentText>
                            <List>
                                {this.state.users.map((users, key) => {
                                    if(this.state.filterByDrivers === true){
                                        if(users.cnh != null){
                                            return (
                                            <div className="listItem" key={key}>
                                                <PersonIcon />
                                                <p>{users.name}</p>
                                                <p>{users.email}</p>
                                                <div className="list-actions">
                                                    {/*<IconButton value={users.id}>
                                                        <EditIcon color="primary" />
                                                    </IconButton>*/}
                                                    {/*<IconButton value={users.id} onClick={() => this.handleModalConfirm(users.id)} >
                                                        <DeleteIcon color="error" />
                                                    </IconButton>*/}
                                                </div>
                                            </div>
                                            )
                                        }
                                    }else {
                                        return (
                                        <div className="listItem" key={key}>
                                            <PersonIcon />
                                            <p>{users.name}</p>
                                            <p>{users.email}</p>
                                            <div className="list-actions">
                                                {/*<IconButton value={users.id}>
                                                    <EditIcon color="primary" />
                                                </IconButton>*/}
                                                {/*<IconButton value={users.id} onClick={() => this.handleModalConfirm(users.id)} >
                                                    <DeleteIcon color="error" />
                                                </IconButton>*/}
                                            </div>
                                        </div>
                                        )
                                    }
                                })}
                            </List>
                            <div className={["list-progress", this.state.progress === true ? 'active' : 'disable'].join(' ')}>
                                <CircularProgress />
                            </div>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withRouter(Users);