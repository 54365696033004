import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import './styles.css';
import './loading.css';
import api from "../../services/api";
import '../Crudreservation/forms.css';
import Aside from '../Components/aside/index';
import { Scrollbars } from 'react-custom-scrollbars';
import Fade from 'react-reveal/Fade';

import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { BulletList } from 'react-content-loader';
import ContentLoader from "react-content-loader";
import AddIcon from '@material-ui/icons/Add';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import HighlightIcon from '@material-ui/icons/Highlight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import WarningIcon from '@material-ui/icons/Warning';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

import DayPicker from 'react-day-picker';
import Helmet from 'react-helmet';
import 'react-day-picker/lib/style.css';

import CloseIcon from '@material-ui/icons/Close';
import Menu from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Crudreservation from "../Crudreservation/newscheduling";

import { ToastContainer, toast } from 'react-toastify';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import DialogContentText from '@material-ui/core/DialogContentText';
import WelcomeBannerImage from '../../assets/images/welcome-banner.png'

import AppTour from "../Components/tour/apptour";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';

var dateFormat = require('dateformat');
const MyBulletListLoader = () => <BulletList />

const MyLoader = () => (
  <ContentLoader 
    height="250px"
    width="500px"
    speed={2}
    primaryColor="#eaeaea"
    //primaryColor="#cad5d9"
    secondaryColor="#eded"
  >
    <rect x="20" y="15" rx="4" ry="4" width="0" height="6" /> 
    <rect x="20" y="15" rx="4" ry="4" width="500" height="250" />
  </ContentLoader>
)

const MONTHS = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
const WEEKDAYS_SHORT = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

/* COMPONENTS */
class Schedules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      schedules : [],
      loading: true,
      loadingcars: true,
      date: new Date(),
      api_token: localStorage.getItem('api_token'),
      username: localStorage.getItem('name'),
      firstaccess: localStorage.getItem('firstaccess'),
      opentour: false,
      vehicles: [],
      checkedA: true,
      filter: '',
      filterDay: '',
      filterVehicles: [],
      el: '',
      schedulesnull: false,
      taskid: '',
      editable: false,
      showComponent: false,
      isOpen: false,
      open: false,
      hidden: false,
      admin: false,
      loadingWithContent: false,
  
      //File Uploader
      file: null,
      imagePreviewUrl: '',
  
      setSuccess: false,
      setLoading: false,
  
      initValidation: false,
  
      //Modal
      openImageModal: false,
      openUpdateModal: false,

      //Update data
      updateId: null,
      updateHoraInicio: null,
      updateHoraFim: null,
      updateReason: '',
      updateVehicle: '',
      checkedVehicleUpdate: false,
      vehiclesToUpdate: [],
      updateDate: null,
      selectUpdateVehicle: null,
      filterBy: localStorage.getItem('filterby'),
      dataPassada: false,
      dataAtual: dateFormat(new Date().toLocaleDateString("en-us"), "yyyy-mm-dd"),


      //Modal Admin edit mode
      showTaskControls: false,
      taskidadmedit: null,
      admEditTaskMode: false,
      admEditTaskData: [],
      hour_i: null,
      hour_e: null,
      date_i_e: null,
      admInputFile: null,
      admOutputFile: null,
      AdmAbastecimento: false,
      fuelAndLiterValidation: false,

  
      setDeleteReservationLoading: false,
      openConfirm: false,
      id: false,
  
      run: false,
      steps: [
        {
          target: '.input-search',
          content: 'This another awesome feature!',
          disableBeacon: true
        },
      ],
      handleWelcomeClose: false,
  
    };
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.selectedDay = new Date();
    this.setNotifyFunction = this.setNotifyFunction.bind(this);
    this.handleShowTaskControls = this.handleShowTaskControls.bind(this);
  }


  componentDidMount = async e => {

    var dayResult = dateFormat(this.state.date.toLocaleDateString('en-US'), "yyyy-mm-dd");
    this.setState({ loading: true, selectedDay: this.state.date, filterDay: dayResult})
    if (localStorage.getItem("api_token") === null) {
      this.props.history.push('/');
      localStorage.removeItem('api_token');
    }else{
      try {
        await api.get('/vehicles?api_token=' + this.state.api_token)
        .then(res => {
          const data = res.data;
          this.setState({ vehicles:data['vehicles'] });
          var vehicles = data['vehicles'].map((item) => { return item.name; });
          this.setState({ loadingcars: false, filterVehicles: vehicles});
        })
        .catch(e => {
          toast.error('Tive um problema de conexão!😕 Não consegui listar os veículos!');
          this.setState({
              setDeleteReservationLoading: false,
          });
        })

        await api.get('/vehicles-reservation?api_token=' + this.state.api_token)
          .then(res => {
            const data = res.data;
            this.setState({ schedules:data['vehicles_reservation'], loading: false });
            var el = document.getElementsByClassName('task').length;
            this.setState({ el: el });
            if(this.state.el <= 0){this.setState({ schedulesnull: true })}else {this.setState({ schedulesnull: false })}
          })
          .catch(e => {
            toast.error('Tive um problema de conexão!😕 Não consegui listar os suas reservas!');
            this.setState({
                setDeleteReservationLoading: false,
            });
          })


        await api.get('/user/info?api_token=' + this.state.api_token)
          .then( res => {
            if(res.data.user_info.type_user.toLowerCase() === 'administrator'){
              this.setState({ admin: true });
            }else {
              this.setState({ admin: false });
            }
          })

        document.getElementById('notify-button').classList.remove('waiting');
          
      } catch (err) {
        this.setState({ error: "Erro inesperado!"});
      }finally {
        this.setState({ loadingcars: false, loading: false});
      }
    }
  }

  handleAttReservations = async e => {
    this.setState({ loadingWithContent: true });
      try {
        await api.get('/vehicles-reservation?api_token=' + this.state.api_token)
          .then(res => {
            const data = res.data;
            if(res.data.error == true){
              toast.warning('🚗 Algo deu errado, não foi possível listar os agendamentos. Tente recarregar a página!');
              this.setState({ loading: false, loadingWithContent: false });
              return false;
            }
            this.setState({ schedules:data['vehicles_reservation'], loading: false });
            var el = document.getElementsByClassName('task').length;
            this.setState({ el: el });
            if(this.state.el <= 0){this.setState({ schedulesnull: true })}else {this.setState({ schedulesnull: false })}
          })
          
          .catch(e => {
            toast.error('Tive um problema de conexão!😕 As reservas podem não estar atualizadas!');
            this.setState({
                setDeleteReservationLoading: false,
                loadingWithContent: false
            });
          })
          
      } catch (err) {
        this.setState({ error: "Erro inesperado!"});
      }finally {
        this.setState({ loadingcars: false, loading: false, loadingWithContent: false});
      }
  }

  handleChecked = e => {
    this.setState(state => ({ checkedA: !state.checkedA }));

    var cars = this.state.filterVehicles;
    if(cars.includes(e.target.value)){
      var array = cars.filter(function(item, i) {
        return item !== e.target.value;
      });
      this.setState({ filterVehicles: array })
    }else {
      this.setState({ filterVehicles:[...this.state.filterVehicles, e.target.value] });
    }
    setTimeout(() => {
      var el = document.getElementsByClassName('task').length;
      this.setState({ el: el });
      if(this.state.el <= 0){this.setState({ schedulesnull: true })}else {this.setState({ schedulesnull: false })}
    }, 50);
  }

  // Filter schedules by Name
  handleFilter = (newFilter) => {
    this.setState(() => ({
      filter: newFilter
    }));
    this.timeout = setTimeout(() => {
      var el = document.getElementsByClassName('task').length;
      this.setState({ el: el });
      if(this.state.el <= 0){this.setState({ schedulesnull: true })}else {this.setState({ schedulesnull: false })}
    }, 100);
  };


  // Toogle 'pick' aside
  handleToogle = (e) => {
    this.setState(state => ({
      toogle: !state.toogle
    }));
  };

  // Click day event on day-picker
  handleDayClick(day) {
    if(!document.getElementById('pick').classList.contains('toogle') && window.innerWidth < 600){
      this.handleToogle();
    }
    this.setState({ schedulesnull: false });
    var dayString = day.toLocaleDateString('en-US');
    var dayResult = dateFormat(dayString, "yyyy-mm-dd");

    // Filter schedules by selected date
    this.setState(() => ({ filterDay: dayResult, selectedDay: day }));
    
    this.timeout = setTimeout(() => {
      var el = document.getElementsByClassName('task').length;
      this.setState({ el: el });
      if(this.state.el <= 0){this.setState({ schedulesnull: true })}else {this.setState({ schedulesnull: false })}
    }, 50);
  }


  // Modal tasks events
  handleViewTask = event => { this.setState({ taskid: event.target.id, editable: true}); };
  handleCloseEditable = e => {
    this.setState({ editable: 'no', imagePreviewUrl: '', openConfirm: false });
    setTimeout(()  => {
      this.setState({ editable: false });
    }, 1000);
  }


  // Modal crud events
  handleAgendamento = e => {
    const modal = document.getElementById('reservationmodal');
    if(modal === null){
      this.setState(state => ({ showComponent: !state.showComponent })); 
    }else {
      this.setState(state => ({ showComponent: !state.showComponent }));
      setTimeout(() => { this.setState(state => ({ showComponent: !state.showComponent })); }, 10);
    }
  }


  handleVisibility = () => {
    this.setState(state => ({
      open: false,
      hidden: !state.hidden,
    }));
  };

  handleClick = () => {
    this.setState(state => ({
      open: !state.open,
    }));
  };

  handleOpen = () => {
    if (!this.state.hidden) {
      this.setState({
        open: true,
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleToogle = () => {
    var pick = document.getElementsByClassName("toogle");
    var pickid = document.getElementById("pick");
    if(pick.length > 0){
      pickid.classList.remove("toogle");
    }else {
      pickid.classList.add("toogle");
    }
  }

  handleFloatingButton = e => {
    switch (e) {
      case 2:
          this.handleAgendamento();
        break;
      case 1:
          this.handleChangeTheme();
        break;
      case 0:
          this.handleLogout();
        break;

      default:
        break;
    }
  }

  handleChangeTheme() {
    var theme = localStorage.getItem('darkmode');
    if(theme === 'true'){
      localStorage.setItem('darkmode', false);
      window.location.reload();
    }else {
      localStorage.setItem('darkmode', true);
      window.location.reload();
    }
  }

  handleImageChange = e => {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file);
  }

  handleAdmImageInputChange = e => {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        admInputFile: reader.result
      });
    }

    reader.readAsDataURL(file);
  }
  handleAdmImageOutputChange = e => {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        admOutputFile: reader.result,
      });
    }

    reader.readAsDataURL(file);
  }

  handleInitRunning = async e => {
    e.preventDefault();
    const data = new FormData(e.target);
    const id = data.get('id');
    this.setState({ setSuccess: false, setLoading: true});

    if(this.state.file === null){
      this.setState({ initValidation: true});
      this.setState({ setSuccess: false, setLoading: false});
      return false;
    }

    this.setState({ initValidation: false});
    try {
      api.post('/vehicles-reservation/start/' + id + '?api_token=' + this.state.api_token, {imageBase64: this.state.imagePreviewUrl})
      .then(res => {
        if(res.data.error == true){
          toast.warning('🚗 Algo deu errado, tente novamente!');
          this.setState({ setLoading: false });
          return false;
        }
        this.handleAttReservations();
        this.setState({ setSuccess: true, setLoading: false, imagePreviewUrl: '', file: null});
        toast.success('🚗 Corrida iniciada com sucesso, tchau!😉');
      })
      .catch(e => {
        toast.error('Tive um problema de conexão!😕 Tente novamente em alguns minutos!');
        this.setState({ setLoading: false, setDeleteReservationLoading: false, });
      })
    } catch (err){
      this.setState({ setSuccess: false, setLoading: false, imagePreviewUrl: '', file: null});
      toast.warning('🚗 Não consegui iniciar sua corrida, desculpa... 😭');
    }
  }

  handleEndRunning = async e => {
    e.preventDefault();
    const data = new FormData(e.target);
    const id = data.get('id');
    this.setState({ setSuccess: false, setLoadingEnd: true});

    if(this.state.file === null){
      this.setState({ initValidation: true});
      this.setState({ setSuccess: false, setLoadingEnd: false});
      return false;
    }

    this.setState({ initValidation: false});

    try {
      api.post('/vehicles-reservation/stop/' + id + '?api_token=' + this.state.api_token, {imageBase64: this.state.imagePreviewUrl})
      .then(res => {
        if(res.data.error == true){
          toast.warning('🚗 Algo deu errado, tente novamente!');
          this.setState({ setLoadingEnd: false });
          return false;
        }
        this.handleAttReservations();
        this.setState({ setSuccess: true, setLoadingEnd: false, imagePreviewUrl: '', file: null});
      })
      .catch( r => {
        toast.warning('🚗 Algo de errado, desculpa... 😭');
      })
    } catch (err){
      this.setState({ setSuccess: false, setLoadingEnd: false, imagePreviewUrl: '', file: null});
      console.error(err);
    }
  }

  handleViewImage = (e) => {
    this.setState({ inputImage: null, outputImage: null})
    var input = e[0];
    var output = e[1];
    var id = e[2];

    try {
      this.setState({
        inputImage: input === null ? null : `https://siscar-api.iteva.org.br/api/vehicles-reservation/images/${id}/${input}?api_token=${this.state.api_token}`,
        outputImage: output === null ? null : `https://siscar-api.iteva.org.br/api/vehicles-reservation/images/${id}/${output}?api_token=${this.state.api_token}`,
        openImageModal: true
      });
    } catch (error) {
      return false;
    }
  }

  handleShowTaskControls() {
    this.setState(state => ({ showTaskControls: !state.showTaskControls}))
  }

  handleAdmEditMode = (e) => {
    if(e[6] !== null || e[7] !== null) {
      this.setState({ AdmAbastecimento: true })
    }
    const data = {
      taskidadmedit: e[0],
      hour_i: e[1],
      hour_e: e[2],
      km_ini: e[4],
      km_fim: e[5],
      supply_type: e[6],
      liters_supplied: e[7],
      input: e[8],
      output: e[9],
    };

    var input = e[8];
    var output = e[9];
    var id = e[0];

    this.setState({
      inputImage: input === null ? null : `https://siscar-api.iteva.org.br/api/vehicles-reservation/images/${id}/${input}?api_token=${this.state.api_token}`,
      outputImage: output === null ? null : `https://siscar-api.iteva.org.br/api/vehicles-reservation/images/${id}/${output}?api_token=${this.state.api_token}`,
      taskidadmedit: e[0], hour_i: e[1], hour_e: e[2], date_i_e: e[3], kminicial: e[4], kmfinal: e[5], gasolinaTipo: e[6], gasolinaLitros: e[7], admEditTaskData: data, admEditTaskMode: true
    });
    setTimeout(() => {
      console.clear();
    }, 100);
  }

  handleOpenUpdateAgendamentoModal = ( id, input, output, reason, vehicle, date ) => {
    let inputWithDate = `${date} ${input}`;
    let outputWithDate = `${date} ${output}`;

    this.setState( state => ({
      updateId: id,
      updateHoraInicio: inputWithDate,
      updateHoraFim: outputWithDate,
      updateReason: reason,
      updateVehicle: vehicle,
      updateDate: date,

      openUpdateModal: !state.openUpdateModal
    }));
  }

  handleUpdateAgendamentoModal = () => {
    this.setState( state => ({
      openUpdateModal: !state.openUpdateModal,
      updateId: null,
      updateHoraInicio: null,
      updateHoraFim: null,
      updateReason: null,
      updateVehicle: null,
      selectUpdateVehicle: null,
      checkedVehicleUpdate: false
    }));
  }

  handleAttUpdateReservationData = (horaTipo, data) => {
    let isValid = !isNaN(data.getTime());

    if( horaTipo === 0 ) {
      this.setState({ updateHoraInicio: data})
    }else if ( horaTipo === 1 ) {
      this.setState({ updateHoraFim: data})
    }
    if(this.state.checkedVehicleUpdate === true && isValid) {
      setTimeout(() => {
        this.setCheckvehicle();
      }, 50);
    }
  }

  setcheckedVehicleUpdate = () => {
    this.setState( state=> ({ checkedVehicleUpdate: !state.checkedVehicleUpdate }));
    setTimeout(() => {
      if(this.state.checkedVehicleUpdate === true ) {
        this.setCheckvehicle();
      }else {
        this.setState({ selectUpdateVehicle: null });
      }
    }, 50);
  }

  setCheckvehicle = async () => {
    const { updateDate } = this.state;
    const response = await api.post("/vehicles-available?api_token=" + this.state.api_token,
    {date: updateDate, hour_initial: dateFormat(this.state.updateHoraInicio, 'HH:MM:ss'), hour_end: dateFormat(this.state.updateHoraFim, 'HH:MM:ss') })
    const data = response.data;
    this.setState({ vehiclesToUpdate: data.vehicles_availables });
  }

  setUpdateDate = (e) => {
    var dateArr = e.target.value.split("-");

    var dateToCompare = new Date(parseInt(dateArr[0]), parseInt(dateArr[1])-1, parseInt(dateArr[2]));

    // Date object for today
    var today = new Date();

    if(dateToCompare < today && e.target.value !== this.state.dataAtual) {
        this.setState({ dataPassada: true });
    }else {
        this.setState({ dataPassada: false });
    }
    this.setState({ updateDate: e.target.value });
}

  handleSendUpdate = async () => {
    this.setState({ setLoading: true });
    const { api_token, updateId, updateHoraInicio, updateHoraFim, updateDate, updateReason, selectUpdateVehicle } = this.state;
    const updateHoraInicioWithFormat = dateFormat(updateHoraInicio, 'HH:MM:ss');
    const updateHoraFimWithFormat = dateFormat(updateHoraFim, 'HH:MM:ss');
    
    let body = null;

    if(selectUpdateVehicle === null) {
      body = {
        _method: 'PATCH',
        hour_initial: updateHoraInicioWithFormat,
        hour_end: updateHoraFimWithFormat,
        date: updateDate,
        reason: updateReason
      };
    }else {
      body = {
        _method: 'PATCH',
        hour_initial: updateHoraInicioWithFormat,
        hour_end: updateHoraFimWithFormat,
        date: updateDate,
        reason: updateReason,
        id_vehicle: selectUpdateVehicle
      };
    }
    
    try {
      await api.post(`/vehicles-reservation/${updateId}?api_token=${api_token}`, body).then(res => {
        if(res.data.error == true){
          toast.warning('🚗 Algo deu errado, tente novamente!');
          this.setState({ setLoading: false });
          return false;
        }
        this.setState({ setLoading: false, openUpdateModal: false });
        toast.success('🚗 Alteração realizada com sucesso!😉');
        this.handleAttReservations();
        this.handleUpdateAgendamentoModal();

      }).catch( err => {
        this.setState({ setLoading: false });
        toast.error('Algo deu errado com a conexão😕 Não foi possível realizar a alteração!');
      })
    } catch (e) {
      toast.error('Algo deu errado!😕 Tente novamente em alguns minutos!');
      this.setState({ setLoading: false });
    }
  }

  handleChangeFilter = (e) => {
    localStorage.setItem('filterby', e);
    this.handleAttReservations();
  }

  handleFilterToggle() {
    var menu = document.getElementById("filter-menu");
    menu.classList.add("active");
  }

  handleFilterMenuExit() {
    var menu = document.getElementById("filter-menu");
    setTimeout(() => {
      menu.classList.remove("active");
    }, 1000);
  }

  handleAdmEditModalOpen = () => {
    this.setState({
      openImageModal: true
    });
  }
 
  handleImageModalClose = e => {
    this.setState(state => ({
        openImageModal: !state.openImageModal
    }));
  }

  handleAdmEditModalClose = e => {
    this.setState(state => ({
        admEditTaskMode: !state.admEditTaskMode,
        AdmAbastecimento: false,
        kminicial: null,
        kmfinal: null,
        supply_type: null,
        gasolinaLitros: null,
        fuelAndLiterValidation: false
    }));
    
  }

  handleAdmAbastecimentos = e => {
    this.setState( state => ({ AdmAbastecimento: !state.AdmAbastecimento }));
  }

  handleSendAdmEdit = async e => {
    var { admInputFile, admOutputFile, date_i_e, hour_i, hour_e, kminicial, kmfinal, gasolinaLitros, gasolinaTipo, AdmAbastecimento } = this.state;
    
    if(hour_i != null) {
      var h_i = dateFormat(hour_i, "HH:MM:ss");
      hour_i = `${date_i_e} ${h_i}`;
    } 

    if(hour_e != null) {
      var h_e = dateFormat(hour_e, "HH:MM:ss");
      hour_e = `${date_i_e} ${h_e}`;
    }

    if(AdmAbastecimento === false) {
      gasolinaLitros = null;
      gasolinaTipo = null;
    }else {
      if(!gasolinaTipo || !gasolinaLitros){
        this.setState({ fuelAndLiterValidation: true });
        return;
      }
    }

    this.setState({ setSuccess: false, setLoading: true, formValidation: false});

    try {
        await api.post(`/vehicles-reservation/updatereservation/${this.state.taskidadmedit}?api_token=` + this.state.api_token,
        { input: hour_i, output: hour_e, input_image_base64: admInputFile, output_image_base64: admOutputFile, km_ini: kminicial, km_fim: kmfinal, liters_supplied: gasolinaLitros, fuel_type: gasolinaTipo })
        .then(res => {
            if(res.data.error == true){
                toast.warning('🚗 Algo deu errado, tente novamente!');
                this.setState({ setLoading: false });
                return false;
            }
            toast.success('🚗 Alteração realizada com sucesso!😉');
            this.setState({ setLoading: false, inputImage: null, outputImage: null });
            this.handleAttReservations();
            this.handleAdmEditModalClose();
        })
        .catch(e => {
            toast.error('Algo deu errado com a conexão😕 Não foi possível realizar a alteração!');
            this.setState({ setLoading: false });
          })
    } catch (err) {
        this.setState({
            setSuccess: false,
            setLoading: false,
        });
        toast.error('Algo deu errado!😕 Tente novamente em alguns minutos!');
    }
  }

  openImageInput = e => {
    let fileName =  getExtensionOfFileFromString(this.state.inputImage);
    download(this.state.inputImage, `Imagem da saída.${fileName}`);
  }

  openImageOutput = e => {
    let fileName =  getExtensionOfFileFromString(this.state.outputImage);
    download(this.state.outputImage, `Imagem da chegada.${fileName}`);
  }

  handleDeleteReservation = e => {
    this.setState({ setDeleteReservationLoading: true });
    var config = {
        _method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        withCredentials: false
    }
    try {
        api.post(`/vehicles-reservation/${e}?api_token=`+ this.state.api_token, config)
        .then ( res => {
          if(res.data.error == true){
            toast.warning('🚗 Algo deu errado, tente novamente!');
            this.setState({ setDeleteReservationLoading: false });
            return false;
          }
            this.handleAttReservations();
            toast.success('🚗 Reserva deletada com sucesso!😉');
            this.setState({ setDeleteReservationLoading: false});
        })
        .catch(e => {
          toast.error('Tive um problema de conexão!😕 Tente novamente em alguns minutos!');
          this.setState({
              setDeleteReservationLoading: false,
          });
        })
    }catch (err){
        toast.error('Algo deu errado!😕 Tente novamente em alguns minutos!');
        this.setState({
            setDeleteReservationLoading: false,
        });
    }
  }

  handleModalConfirm = async e => {
    this.setState(state => ({
        openConfirm: !state.openConfirm
    }));
    this.setState({ id: e });
  }

  handleWelcomeClose = e => {
    e.preventDefault();
    this.setState({ firstaccess: false, handleWelcomeClose: false });
    localStorage.setItem('firstaccess', false);
  }

  handleInitTour = e => {
    e.preventDefault();
    this.setState({ firstaccess: false, handleWelcomeClose: false });
    localStorage.setItem('firstaccess', false);
    this.setState({ opentour: true });
  }

  setNotifyFunction(date, e) {
    this.handleDayClick(new Date(date.split('-').join('/')))
    this.setState({ taskid: e, editable: true})
  }

  render() {

    const dayfilter = this.state.filterDay;
    const { hidden, open } = this.state;

    let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (<img src={imagePreviewUrl} alt="Upload preview" />);
    }

    const highlighted = [];
    { 
      Object.entries(this.state.schedules).map(([key, value]) => {
        { 
          Object.entries(value).map(([i, val]) => {
            var date = new Date(i);
            var date2 = date.setDate(date.getDate() + 1);
            highlighted[key] = new Date(date2);
          })
        }
      })
    }
    const modifiers = {highlighted};

    const actions = [
      { icon: <ExitToAppIcon />, name: 'Fazer Logoff' },
      { icon: <HighlightIcon />, name: 'Modo Noturno' },
      { icon: <AddIcon />, name: 'Novo agendamento' },
    ];

    const fuelTypes = {
      1: 'Gasolina Comum',
      2: 'Gasolina Aditivada',
      3: 'Diesel',
      4: 'Álcool',
      5: 'Etanol',
    };

    return (
      <div className="container todo">

        { this.state.firstaccess === 'true' ?
           <Dialog
              className="welcome-modal"
               fullScreen={this.state.fullScreen}
               open={this.state.firstaccess}
               onClose={this.handleWelcomeClose}
               aria-labelledby="responsive-dialog-title"
               maxWidth={false}
               >
               <DialogContent>
                   <img className="welcome-img" src={WelcomeBannerImage} alt="Bem vindo!"/>
                   <DialogContentText>
                   </DialogContentText>
                   <h2> Olá {this.state.username}, seja muito bem-vindo(a) ao SisCar 😉</h2>
                   <p>Notamos que este é o seu primeiro acesso neste computador, para uma melhor experiência sujerimos que você conheças mais sobre o nosso sistemas atravez de um tour pelas funcionalidades básicas. 😎</p>

               </DialogContent>

               <DialogActions>
                   <Fab variant="extended" size="large" color="primary" aria-label="Add" onClick={this.handleInitTour}>Sim, desejo realizar o tour</Fab>
                   <Button onClick={this.handleWelcomeClose} color="primary">
                   Não obrigado, já conheço tudo
                   </Button>
               </DialogActions>
           </Dialog>
        : null }

        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
            />
        <ToastContainer />
        <Aside setNotifyFunction={this.setNotifyFunction} />   
        <div className="calendar">
            <div className="floatingaction">
              <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                hidden={hidden}
                icon={<SpeedDialIcon />}
                onBlur={this.handleClose}
                onClick={this.handleClick}
                onClose={this.handleClose}
                onFocus={this.handleOpen}
                onMouseLeave={this.handleClose}
                open={open}
              >
                {actions.map((action, key) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={(e) => this.handleFloatingButton(key)}
                  />
                ))}
              </SpeedDial>
            </div>
            <div className={["pick", this.state.toogle === true ? "toogle" : ""].join(' ')} id="pick">
              <Scrollbars>
                <IconButton className="iconButton-search toogle-button-pick" aria-label="Search" onClick={(e) => this.handleToogle(e)}>
                  <NavigateBeforeIcon />
                </IconButton>
                <div className="container">
                  <Fab className="btn-add-aside" color="primary" variant="extended" aria-label="Delete" onClick={this.handleAgendamento}>
                    <AddIcon className="addextended" />
                    Novo agendamento
                  </Fab>
                  {/* <CalendarPicker /> */}
                  <div>
                    <Helmet>
                      <style>{`
                        .DayPicker-Day--highlighted  {
                          background-color: #111e6829;
                        }`
                      }</style>
                    </Helmet>
                    <DayPicker
                      modifiers={modifiers}
                      selectedDays={this.state.selectedDay}
                      onDayClick={this.handleDayClick}
                      //showOutsideDays
                      month={new Date()}
                      months={MONTHS}
                      weekdaysShort={WEEKDAYS_SHORT}
                    />
                  </div>
                </div>
                <div className="Container check">
                  <div className="car-list">
                    <div className="car-list-action">
                      <p>Veículos disponíveis</p>
                    </div>
                    <div className={"car-content-loader " + (this.state.loadingcars === true ? "active" : "")}>
                      <MyBulletListLoader />
                    </div>
                    <FormGroup className="car-list-check">
                      {this.state.vehicles.map((vehicles, key) =>
                          <FormControlLabel key={key} control={<Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />}
                          defaultChecked={this.state.checkedA}
                          onChange={(e) => this.handleChecked(e)}
                          color="primary"
                          value={vehicles.name}
                          />}
                      label={vehicles.name}
                        />
                      )}
                    </FormGroup>
                  </div>
                </div>
              </Scrollbars>
            </div>
            <div className="list">
            
              <div className={"schedulesnull " + (this.state.schedulesnull === true ? "active" : "disable")}>
                <p>Nenhum agendamento para hoje :(</p>
              </div>

              <Scrollbars>
                <div className="task-group">
                  <Filter handleFilter={this.handleFilter} handleToogle={this.handleToogle} handleChangeFilter={this.handleChangeFilter} handleFilterToggle={this.handleFilterToggle} handleFilterMenuExit={this.handleFilterMenuExit} />
                  <div className="tasks-list">
                    <div className={["loading-with-content", this.state.loadingWithContent ? 'active' : ''].join(' ')}>
                      <CircularProgress />
                    </div>
                    <div className={"loading-interface-cars "  + (this.state.loading === true ? "active" : " ")}> <MyLoader /> </div>
                    <div className={"loading-interface-cars "  + (this.state.loading === true ? "active" : " ")}> <MyLoader /> </div>
                    <div className={"loading-interface-cars "  + (this.state.loading === true ? "active" : " ")}> <MyLoader /> </div>
                    <div className={"loading-interface-cars "  + (this.state.loading === true ? "active" : " ")}> <MyLoader /> </div>
                  
                    {
                       Object.entries(this.state.schedules).map(([key, value]) => {
                        {
                            if(value[dayfilter] !== undefined &&
                              value[dayfilter].user.name.toLowerCase().includes(this.state.filter.toLowerCase()) &&
                              value[dayfilter].vehicle !== null &&
                              this.state.filterVehicles.includes(value[dayfilter].vehicle.name)){
                              return (
                                <Fade key={key} bottom>
                                  <div className="shadow">
                                    <div id={key} key={key} className={["task", this.state.username === value[dayfilter].user.name ? 'task-user' : '',
                                    this.state.editable === true && this.state.taskid == value[dayfilter].id ? "editable" : "",
                                    this.state.editable === 'no' && this.state.taskid == value[dayfilter].id ? "no-editable" : "",
                                    value[dayfilter].input != null && value[dayfilter].output != null ? "endtask" : ""].join(' ')}>
                                        <span className="task-more" onClick={this.handleShowTaskControls}>
                                          <IconButton aria-label="Search" color="inherit" className="toogleButton close-task">
                                            <MoreVertIcon />
                                          </IconButton>
                                        </span>
                                        <span className="close-task" onClick={this.handleCloseEditable}>
                                          <IconButton aria-label="Search" color="inherit" className="toogleButton close-task">
                                            <CloseIcon />
                                          </IconButton>
                                        </span>
                                        <div className="click" onClick={this.handleViewTask} id={value[dayfilter].id}></div>
                                        <div className={["modalConfirm", this.state.openConfirm === true && this.state.id === value[dayfilter].id ? "active" : "disable"].join(" ")}>
                                            <WarningIcon></WarningIcon>
                                            <h2>Deseja realmente deletar este agendamento?</h2>
                                            <div className="controls">
                                                <div className="wrapper">
                                                    <Button variant="contained" onClick={() => this.handleModalConfirm(null)} disabled={this.state.setDeleteReservationLoading}>
                                                        Não, Cancelar
                                                    </Button>
                                                </div>
                                                <div className="wrapper">
                                                    <Button variant="contained" color="primary" onClick={() => this.handleDeleteReservation(value[dayfilter].id)} className="btn-send" disabled={this.state.setDeleteReservationLoading}>
                                                        Sim, Deletar agendamento
                                                    </Button>
                                                    {this.state.setDeleteReservationLoading === true ? <CircularProgress size={5} className="buttonProgress" /> : null}                                              </div>
                                            </div>
                                        </div>

                                        <div className="task-header">
                                          <div className="header-line">
                                            <PersonOutlineIcon /> <p><b>{value[dayfilter].user.name}</b></p>
                                          </div>
                                          <div className="header-line">
                                            <DirectionsCarIcon /> <p>{value[dayfilter].vehicle.name}</p>
                                          </div>
                                          <div className="header-line">
                                            <DateRangeIcon /> <p>{value[dayfilter].date.split('-').reverse().join('/')}</p>
                                          </div>

                                        </div>
                                        <div className="header-toogle">
                                          <div className={["controls-images-preview", this.state.showTaskControls ? 'show' : ''].join(" ")}>
                                            {value[dayfilter].input === null && this.state.username === value[dayfilter].user.name || this.state.admin === true && value[dayfilter].input === null ?
                                              <div className="wrapper">
                                                <Button variant="contained" color="primary" onClick={() => this.handleModalConfirm(value[dayfilter].id)} disabled={this.state.setDeleteReservationLoading}>
                                                  Excluir
                                                </Button>
                                                {this.state.setDeleteReservationLoading === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                                              </div>
                                              :
                                              <Button variant="contained" color="primary" onClick={(e) => this.handleViewImage([value[dayfilter].input_image, value[dayfilter].output_image,value[dayfilter].id])}>
                                                Ver imagens
                                              </Button>
                                            }
                                            {
                                              this.state.username === value[dayfilter].user.name && value[dayfilter].input === null || this.state.admin === true && value[dayfilter].input === null ?
                                                <Button variant="contained" color="primary" onClick={(e) => this.handleOpenUpdateAgendamentoModal(value[dayfilter].id, value[dayfilter].hour_initial, value[dayfilter].hour_end, value[dayfilter].reason, value[dayfilter].vehicle.name, value[dayfilter].date)}>
                                                  Editar agendamento
                                                </Button>
                                              : null
                                            }
                                            {this.state.admin === true ?                                     
                                              <Button variant="contained" color="primary" onClick={(e) => this.handleAdmEditMode([value[dayfilter].id, value[dayfilter].input, value[dayfilter].output, value[dayfilter].date, value[dayfilter].km_ini, value[dayfilter].km_fim, value[dayfilter].fuel_type, value[dayfilter].liters_supplied, value[dayfilter].input_image, value[dayfilter].output_image ])} >
                                                Alterar Dados
                                              </Button>
                                            : null }
                                          </div>
                                        </div>

                                        <div className="task-body">
                                          <div className="bodyContainer">
                                            <div className="header-line">
                                              <p><b>Motivo:&nbsp;</b>{value[dayfilter].reason} {value[dayfilter].reason == 'O condutor preferiu não especificar o motivo' ? ' 🤷‍♀' : null}</p>
                                            </div>
                                          </div>
                                          <div className="bodyContainer">
                                            <div className="header-line">
                                              {value[dayfilter].input != null ? <TimerOffIcon /> : <TimelapseIcon />} <p>&nbsp;{value[dayfilter].hour_initial}</p>
                                            </div>
                                            <span className="line-pointer"></span>
                                            <div className="header-line">
                                              {value[dayfilter].output != null ? <TimerOffIcon /> : <TimelapseIcon />} <p>&nbsp;{value[dayfilter].hour_end}</p>
                                            </div>
                                            <div className={["task-actions", value[dayfilter].input != null || value[dayfilter].output != null ? 'active' : ''].join(' ')}>
                                              {value[dayfilter].input != null && value[dayfilter].output != null ? null
                                              : <div id="loader">
                                                  <div id="top"></div>
                                                  <div id="bottom"></div>
                                                  <div id="line"></div>
                                                </div>
                                              }
                                            </div>
                                          </div>
                                          {this.state.editable === true &&
                                            this.state.taskid == value[dayfilter].id &&
                                            this.state.username === value[dayfilter].user.name &&
                                            value[dayfilter].input === null ?
                                              <div className="taskform">
                                                <div className="App">
                                                  <form onSubmit={this.handleInitRunning}>
                                                    {/*<CloudUploadIcon className="icon-upload" />*/}
            
                                                    <input type="file" accept=".png, .jpg, .jpeg" name="image" onChange={(e) => this.handleImageChange(e)} />
                                                    <div className="imgPreview">
                                                      {$imagePreview}
                                                    </div>
                                                    
                                                    <input type="hidden" name="id" value={value[dayfilter].id} />
                                                    {this.state.initValidation === true ? 
                                                      <span className="spanerror">Selecione uma imagem para continuar...</span>
                                                    : null}
                                                    <div className="wrapper">
                                              
                                                      <Button type="submit" variant="contained" color="primary" value={value[dayfilter].id} disabled={this.state.setLoading}>
                                                        Iniciar corrida
                                                      </Button>
                                                      {this.state.setLoading === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                            : null }

                                          {this.state.editable === true &&
                                            this.state.taskid == value[dayfilter].id &&
                                            this.state.username === value[dayfilter].user.name &&
                                            value[dayfilter].input != null &&
                                            value[dayfilter].output === null ? 
                                            <div className="taskform">
                                              <div className="App">
                                              <form onSubmit={this.handleEndRunning}>
                                                <input type="file" accept=".png, .jpg, .jpeg" name="image" onChange={(e) => this.handleImageChange(e)} />
                                                  <div className="imgPreview">
                                                    {$imagePreview}
                                                  </div>
                                                  
                                                  <input type="hidden" name="id" value={value[dayfilter].id} />
                                                  {this.state.initValidation === true ? 
                                                    <span className="spanerror">Selecione uma imagem para continuar...</span>
                                                  : null}
                                                  <div className="wrapper">
                                                    <Button type="submit" variant="contained" color="primary" value={value[dayfilter].id} disabled={this.state.setLoadingEnd}>
                                                      Encerrar corrida
                                                    </Button>
                                                    {this.state.setLoadingEnd === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                                                  </div>
                                              </form>
                                              </div>
                                              </div>
                                            : null}
                                            {this.state.editable === true &&
                                            this.state.taskid == value[dayfilter].id &&
                                            value[dayfilter].input != null &&
                                            value[dayfilter].output != null ? 
                                              <div className="taskform">
                                                <div className="taskformend">
                                                  <div className="header-line">
                                                    <p>O usuário que fez esta reserva já retornou.</p>
                                                  </div>
                                                  <div className="header-line">
                                                    <TimerOffIcon /><p>&nbsp;Entrada:&nbsp; {value[dayfilter].input.split(" ")[1]}</p>
                                                  </div>
                                                  <div className="header-line">
                                                    <TimerOffIcon /><p>&nbsp;Saída:&nbsp; {value[dayfilter].output.split(" ")[1]}</p>
                                                  </div>
                                                </div>
                                              </div>
                                            : null }
                                        </div>
                                    </div>
                                  </div>
                                </Fade>
                              )
                            }
                        }
                     })
                    }
                  </div>
                </div>
              </Scrollbars>
            </div>
        </div>
        {this.state.showComponent ? <Crudreservation handleAttReservations={this.handleAttReservations} /> : null}
        {this.state.opentour ? <AppTour /> : null}

        <div id="images">
                <Dialog
                    fullScreen={this.state.imageModalfullScreen}
                    open={this.state.openImageModal}
                    onClose={this.handleImageModalClose}
                    aria-labelledby="responsive-dialog-title"
                    >
                    <span className="close-imagemodal" onClick={this.handleImageModalClose}>
                      <IconButton aria-label="Search" color="inherit" className="toogleButton close-task">
                        <CloseIcon />
                      </IconButton>
                    </span>
                    <DialogTitle id="responsive-dialog-title">{"Imagens de saída e chegada"}</DialogTitle>
                    <DialogContent className="imagesList">
                          <div className="input">
                            {this.state.inputImage === null ?
                              <p>O condutor ainda não enviou imagem de saída ou não deu início na corrida!</p> : 
                              <div>
                                <p>Imagem da saída</p>
                                <div className="containerImg">
                                  <div className="imageClick" onClick={this.openImageInput}>Click para fazer o download da imagem</div>
                                  <img src={this.state.inputImage} width="auto" height="250" alt="Entrada" />
                                </div>
                              </div>
                            }
                          </div>
                          <div className="output">
                            {this.state.outputImage === null ?
                              <p>O condutor ainda não enviou imagem de chegada ou não finalizou a corrida!</p> : 
                              <div>
                                <p>Imagem da chegada</p>
                                <div className="containerImg">
                                  <div className="imageClick" onClick={this.openImageOutput}>Click para fazer o download da imagem</div>
                                  <img src={this.state.outputImage} width="auto" height="250" alt="Saída" />
                                </div>
                              </div>
                            }
                          </div>
                    </DialogContent>
                    <DialogActions>
                        <Button size="large" color="primary" aria-label="Add" onClick={this.handleImageModalClose} >&nbsp;&nbsp;Fechar&nbsp;&nbsp;</Button>
                    </DialogActions>
                </Dialog>
            </div>

        <div id="admEditTaskMode">
                <Dialog
                    fullScreen={this.state.ModalfullScreen}
                    open={this.state.admEditTaskMode}
                    onClose={this.handleAdmEditModalClose}
                    aria-labelledby="responsive-dialog-title"
                    >
                    <span className="close-imagemodal" onClick={this.handleAdmEditModalClose}>
                      <IconButton aria-label="Search" color="inherit" className="toogleButton close-task">
                        <CloseIcon />
                      </IconButton>
                    </span>
                    <DialogTitle id="responsive-dialog-title">{"Alterar dados do agendamento"}</DialogTitle>
                    <DialogContent className="imagesList admDataEditTask">
                          <div className="input">
                            <div className="inline-picker">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <Grid container justify="space-between">
                                      <KeyboardTimePicker
                                      margin="normal"
                                      id="mui-pickers-time"
                                      label="Hora saída"
                                      format="HH:mm"
                                      value={this.state.hour_i}
                                      onChange = {(e) => this.setState({ hour_i: e })}
                                      KeyboardButtonProps={{
                                          'aria-label': 'change time',
                                      }}
                                      />
                                      <KeyboardTimePicker
                                      margin="normal"
                                      id="mui-pickers-time"
                                      label="Hora chegada"
                                      format="HH:mm"
                                      value={this.state.hour_e}
                                      onChange={(e) => this.setState({ hour_e: e })}
                                      KeyboardButtonProps={{
                                          'aria-label': 'change time',
                                      }}
                                      />
                                  </Grid>
                              </MuiPickersUtilsProvider>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <Grid container justify="space-between">
                                    <TextField
                                      id="standard-dense"
                                      label="Km inicial"
                                      margin="dense"
                                      defaultValue={this.state.kminicial}
                                      onChange={(e) => this.setState({ kminicial: e.target.value })}
                                    />
                                    <TextField
                                      id="standard-dense"
                                      label="Km final"
                                      margin="dense"
                                      defaultValue={this.state.kmfinal}
                                      onChange={(e) => this.setState({ kmfinal: e.target.value })}
                                    />
                                  </Grid>
                              </MuiPickersUtilsProvider>

                              <div className="admModeInputGroup">
                                <div className="input-wrapper">
                                  <label>Imagem saída:</label>
                                  <div className="input-wrapper-container">
                                    {
                                      this.state.admEditTaskData['input'] !== null ?
                                        <div className="imgAdmPreview">
                                            <EyeIcon onClick={this.handleAdmEditModalOpen}/>
                                        </div>
                                      : null
                                    }
                                    <input type="file" accept=".png, .jpg, .jpeg" name="image" onChange={(e) => this.handleAdmImageInputChange(e)} />
                                  </div>
                                </div>
                                <div className="input-wrapper">
                                  <label>Imagem chegada:</label>
                                  <div className="input-wrapper-container">
                                    {
                                      this.state.admEditTaskData['output'] !== null ?
                                        <div className="imgAdmPreview">
                                          <EyeIcon onClick={this.handleAdmEditModalOpen}/>
                                        </div>
                                      : null  
                                    }
                                    <input type="file" accept=".png, .jpg, .jpeg" name="image" onChange={(e) => this.handleAdmImageOutputChange(e)} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="inline-picker full-width switch">
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={this.state.AdmAbastecimento}
                                    onChange={this.handleAdmAbastecimentos}
                                    value="checkedB"
                                    color="primary"
                                  />
                                }
                                label={this.state.AdmAbastecimento ? "Abastecimento adicionado" : "Abasteceu o veiculo?"}
                              />
                            </div>
                            <div className={["inline-picker full-width abastecimento-input", this.state.AdmAbastecimento ? 'active' : 'disabled'].join(' ')}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <Grid container justify="space-around">
                                    <TextField
                                      id="standard-dense"
                                      label="Quantidade de litros"
                                      margin="dense"
                                      defaultValue={this.state.gasolinaLitros}
                                      onChange={(e) => this.setState({ gasolinaLitros: e.target.value })}
                                    />
                                  </Grid>
                                  <Grid container justify="space-around">
                                    <div className="select MuiFormControl-root">
                                        <select defaultValue={this.state.gasolinaTipo !== null ? this.state.gasolinaTipo : ''} className="select-text" name="id_user" required onChange={(e) => this.setState({ gasolinaTipo: e.target.value })}>
                                          {
                                            this.state.gasolinaTipo === null ?
                                              <option value='' disabled ></option>
                                            : null
                                          }
                                          {Object.entries(fuelTypes).map(([index, data]) => {                                           
                                            return <option key={index} value={index}  selected={this.state.gasolinaTipo === data ? 'true' : 'false'}>{data}</option>                                             
                                          })}                                  
                                        </select>
                                        {this.state.setLoadingCondutor === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                                        <span className="select-highlight"></span>
                                        <span className="select-bar"></span>
                                        <label className="select-label">Qual combustível foi usado?</label>
                                    </div>
                                  </Grid>
                              </MuiPickersUtilsProvider>
                            </div>
                          </div>
                    
                    
                    </DialogContent>
                    {this.state.fuelAndLiterValidation ? 
                        <DialogActions>
                            <span className="spanerror">Se o abastecimento estiver habilitado, todos os campos devem ser preenchidos!</span>
                        </DialogActions>
                    : null}
                    <DialogActions>
                      {
                        this.state.fuelAndLiterValidation ?
                        <p></p>
                        : null
                      }
                        <div className="wrapper">
                            <Fab variant="extended" size="large" color="primary" aria-label="Add" onClick={this.handleSendAdmEdit} className={["btn-send",this.state.setSuccess === true ? 'buttonSuccess' : ''].join(" ")} disabled={this.state.setLoading}>Alterar</Fab>
                            {this.state.setLoading === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                        </div>
                        <Button size="large" color="primary" aria-label="Add" onClick={this.handleAdmEditModalClose} >&nbsp;&nbsp;Fechar&nbsp;&nbsp;</Button>
                    </DialogActions>
                </Dialog>
            </div>
      
        <div id="updateSchedulingModal">
                <Dialog
                    fullScreen={this.state.ModalfullScreen}
                    open={this.state.openUpdateModal}
                    onClose={this.handleUpdateAgendamentoModal}
                    aria-labelledby="responsive-dialog-title"
                    >
                    <span className="close-imagemodal" onClick={this.handleUpdateAgendamentoModal}>
                      <IconButton aria-label="Search" color="inherit" className="toogleButton close-task">
                        <CloseIcon />
                      </IconButton>
                    </span>
                    <DialogTitle id="responsive-dialog-title">{"Editar agendamento"}</DialogTitle>
                    <DialogContent className="imagesList admDataEditTask">
                          <div className="input">
                            <div className="inline-picker">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <Grid container justify="space-between">
                                      <KeyboardTimePicker
                                        margin="normal"
                                        id="mui-pickers-time"
                                        label="Hora saída"
                                        format="HH:mm"
                                        value={this.state.updateHoraInicio}
                                        onChange = {(e) => this.handleAttUpdateReservationData(0, e)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                      />
                                      <KeyboardTimePicker
                                        margin="normal"
                                        id="mui-pickers-time"
                                        label="Hora chegada"
                                        format="HH:mm"
                                        value={this.state.updateHoraFim}
                                        onChange={(e) => this.handleAttUpdateReservationData(1, e)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                      />
                                  </Grid>
                              </MuiPickersUtilsProvider>

                              <div className="update-modal-full-inputs">
                                <TextField onChange={(e) => this.setUpdateDate(e)} id="date" label="Data do agendamento" type="date" defaultValue={this.state.updateDate} InputLabelProps={{shrink: true,}}/>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <>
                                    <Grid container justify="space-around">
                                      <TextField
                                        id="standard-dense"
                                        label="Motivo"
                                        margin="dense"
                                        defaultValue={this.state.updateReason}
                                        onChange={(e) => this.setState({ updateReason: e.target.value })}
                                      />
                                    </Grid>
                                    <p>Veículo: {this.state.updateVehicle}</p>
                                    <div className="flex-switch-box">
                                      <FormControlLabel
                                        control={
                                          <Switch color="primary" checked={this.state.checkedVehicleUpdate} onChange={this.setcheckedVehicleUpdate} value="checkedA" />
                                        }
                                        label={this.state.checkedVehicleUpdate ? 'Mudança de veículo adicionada' : 'Mudar o veículo?'}
                                      />
                                    </div>

                                    {
                                      this.state.checkedVehicleUpdate ?
                                        <Grid container justify="space-around">
                                          <div className="select MuiFormControl-root">
                                              <select className="select-text" required onChange={(e) => this.setState({ selectUpdateVehicle: e.target.value })}>                             
                                                <option value='' disabled selected ></option>
                                                {
                                                  this.state.vehiclesToUpdate.map((item, i) => {                                           
                                                    return <option key={i} value={item.id}>{item.name}</option>                                             
                                                  })
                                                }                                  
                                              </select>
                                              {this.state.setLoadingCondutor === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                                              <span className="select-highlight"></span>
                                              <span className="select-bar"></span>
                                              <label className="select-label">Veículos disponíveis</label>
                                          </div>
                                        </Grid>
                                      : null
                                    }
                                  </>
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>
                          </div>
                    
                    
                    </DialogContent>
                    <DialogActions>
                    {this.state.dataPassada === true ? 
                        <DialogActions>
                            <span className="spanerror">Ah Claro, eu sei que você quer agendar para uma data passada. Mas isso não é possível se você não for um administrador. :/</span>
                        </DialogActions>
                    : null}
                        <div className="wrapper">
                            <Fab variant="extended" size="large" color="primary" aria-label="Add" onClick={this.handleSendUpdate} className={["btn-send",this.state.setSuccess === true ? 'buttonSuccess' : ''].join(" ")} disabled={this.state.setLoading}>Alterar</Fab>
                            {this.state.setLoading === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                        </div>
                        <Button size="large" color="primary" aria-label="Add" onClick={this.handleUpdateAgendamentoModal} >&nbsp;&nbsp;Fechar&nbsp;&nbsp;</Button>
                    </DialogActions>
                </Dialog>
            </div>
      
      </div>
    );
  }
}

const Filter = (props) => (
  <div className="search-box-input">
    <Paper className="root-search">
      <IconButton className="iconButton-search btn-search-toogle" aria-label="Search" onClick={(e) => props.handleToogle(e)}>
        <MenuIcon />
      </IconButton>
      <InputBase
        onChange={(e) => {props.handleFilter(e.target.value);}}
        className="input-search"
        placeholder="   Pesquisar pelo nome do condutor"
        inputProps={{ 'aria-label': 'Search Schedules' }}
      />
      <IconButton className="iconButton-search" aria-label="Search">
        <SearchIcon />
      </IconButton>
      {/*
      <IconButton className="iconButton-search" aria-label="Filter"  onClick={() => props.handleFilterToggle()} onMouseLeave={() => props.handleFilterMenuExit()}>
        <FilterListIcon />
      </IconButton>
        <div className="filter-menu" id="filter-menu">
          <FormControl component="fieldset" >
            <FormLabel component="legend" className="filter-menu-label">Filtrar agendamentos</FormLabel>
            <RadioGroup aria-label="gender" name="gender2" value={localStorage.getItem('filterby')} onChange={(e) => {props.handleChangeFilter(e.target.value)}} >
              <FormControlLabel
                value="0"
                control={<Radio color="primary" />}
                label="Ordem alfabética"
                labelPlacement="end"
              />
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label="Pelo horário"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </div>
      */}
    </Paper>
  </div>
);

const Toogle = (props) => (
    <IconButton aria-label="Search" color="inherit" className="toogleButton" onClick={(e) => {props.handleToogle(e);}}>
      <Menu />
    </IconButton>
)

function download(url, fileName) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';

  xhr.onprogress = function(event) {
      if (event.lengthComputable) {
          var percentComplete = (event.loaded / event.total)*100;
          //yourShowProgressFunction(percentComplete);
      } 
  };

  xhr.onload = function(event) {
      if (this.status == 200) {
          _saveBlob(this.response, fileName);
      }
      else {
          //yourErrorFunction()
      }
  };

  xhr.onerror = function(event){
      //yourErrorFunction()
  };

  xhr.send();
}


function _saveBlob(response, fileName) {
  if(navigator.msSaveBlob){
      //OK for IE10+
      navigator.msSaveBlob(response, fileName);
  }
  else{
      _html5Saver(response, fileName);
  }
}

function _html5Saver(blob , fileName) {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  document.body.removeChild(a);
}

function getExtensionOfFileFromString(myString) {
  let string = myString;
  return string.substring(string.lastIndexOf(".")+1).split("?")[0];
}

export default withRouter(Schedules);