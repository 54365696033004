import React, { Component } from "react";
import '../forms.css';
import api from "../../../services/api";
import { withRouter } from "react-router-dom";


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { toast } from 'react-toastify';

//var dateFormat = require('dateformat');

class NewUser extends Component {
    state = {
        open: true,
        fullScreen: false,
        api_token: localStorage.getItem('api_token'),
        username: localStorage.getItem('name'),
        progress: true,
        name: '',
        email: '',
        password: '',
        checked: false,
        cnh: '',
        formValidation: false,

        //BUTTON LOADING
        setSuccess: false,
        setLoading: false,
    }


    //CRUD EVENTS
    handleNewVehicle = e => {
        const { name, email, password, cnh } = this.state;
        if( !name || !email || ! password ) {
            this.setState({ formValidation: true});
            return false;
        }
        if( this.state.checked === true && !cnh ){
            this.setState({ formValidation: true});
            return false;
        }

        this.setState({ setSuccess: false, setLoading: true, formValidation: false});
        try {
            api.post('/register', { name, email, password, type_user: this.state.checked === true ? 'driver' : 'administrator', cnh})
            .then ( res => {
                if(res.data.error == true){
                    toast.warning('🚗 Algo deu errado, tente atualizar a página!');
                    this.setState({ setLoading: false });
                    return false;
                }
                toast.success('🚗 Usuário cadastrado com sucesso!😉');
                this.setState({ setSuccess: true, setLoading: false});
            })
        }catch (err){
            this.setState({
                setSuccess: false, setLoading: true,
            });
            toast.error('Algo deu errado!😕 Tente novamente em alguns minutos!');
        }
    }



    handleClose = async e => {
        this.setState(state => ({
            open: !state.open
        }));
    }

    render(){
        return (
            <div id="newuser">
                {/*
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
                <ToastContainer />
                */}
                <Dialog
                    fullScreen={this.state.fullScreen}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                    >
                    <DialogTitle id="responsive-dialog-title">{"Cadastrar novo usuário"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                            <form autoComplete="off" className="formSchedule">
                                <TextField id="standard-dense" margin="dense" label="Nome" onChange={(e) => this.setState({ name: e.target.value })} />
                                <TextField id="standard-dense" margin="dense" type="email" label="Email" onChange={(e) => this.setState({ email: e.target.value })} />
                                <TextField id="standard-dense" margin="dense" type="password" label="Senha" onChange={(e) => this.setState({ password: e.target.value })} />
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>Administrador</Grid>
                                    <Grid item>
                                        <Switch
                                            checked={this.state.checked}
                                            onChange={(e) => this.setState(state => ({ checked: !state.checked }))}
                                            value="checkedA"
                                        />
                                    </Grid>
                                    <Grid item>Condutor</Grid>
                                </Grid>
                                <TextField id="standard-dense" margin="dense" type="text" label="CNH" className={this.state.checked === true ? '' : ''} onChange={(e) => this.setState({ cnh: e.target.value })} />
                            </form>
                    </DialogContent>

                    {this.state.formValidation === true ? 
                        <DialogActions>
                            <span className="spanerror">Preencha corretamente todos os campos</span>
                        </DialogActions>
                    : null}

                    <DialogActions>
                        <div className="wrapper">
                            <Fab variant="extended" size="large" color="primary" aria-label="Add" onClick={this.handleNewVehicle} className={["btn-send",this.state.setSuccess === true ? 'buttonSuccess' : ''].join(" ")} disabled={this.state.setLoading}>Cadastrar</Fab>
                            {this.state.setLoading === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                        </div>
                        <Button onClick={this.handleClose} color="primary">
                        Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withRouter(NewUser);