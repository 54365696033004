import React, { Component } from "react";
import Joyride from 'react-joyride';

class AppTour extends Component {
    constructor(props) {
      super(props);
      this.state = {
        run: true,
        steps: [
          {
            target: '.list',
            title: 'Listagem',
            content: 'Aqui serão listados todos os agendamentos. 😉',
            locale: { skip: <p aria-label="skip">Fechar tour</p> },
            disableBeacon: true
          },
          {
            target: '.task',
            title: 'O Agendamento',
            content: 'Clickando aqui você verá os dados da reserva e poderá iniciar a sua saída com um veículo. 🚗',
            disableBeacon: true
          },
          {
            target: '.root-search',
            title: 'Pesquisar',
            content: 'Aqui você pode pesquisar agendamentos pelo nome do condutor. 🕵️',
            disableBeacon: true
          },
          {
            target: '.btn-add-aside',
            title: 'Criar novo agendamento',
            content: 'Aqui abre o formulário de reserva de veículos. É só agendar e sair! 🆕🚘',
            disableBeacon: true
          },
          {
            target: '.DayPicker',
            title: 'Calendário',
            content: 'Você pode escolher o dia que quer vizualizar os agendamentos, e os dias que tiverem alguma reserva estarão destacados. 📅',
            disableBeacon: true
          },
          {
            target: '.car-list-check',
            title: 'Filtragem por veículos',
            content: 'Aqui você pode filtrar os agendamento pelo nome do veículo. 🚗',
            disableBeacon: true
          },
          {
            target: '.aside',
            title: 'Funções de cadastro e listagem',
            content: 'Aqui estão as listagens e cadastros de usuários e veículos. Mas só quem pode usar estes recursos são os usuários com permissões especiais. 😎',
            disableBeacon: true
          },
          {
            target: '.profile',
            title: 'Menu do usuário',
            content: 'Aqui abrirá um menu onde você pode deslogar, fazer novamente este tour e ativar o modo noturno. 🚶😴',
            disableBeacon: true
          },
          {
            target: '#root',
            title: 'SisCar',
            content: 'Então é isso... Agora você pode agendar e sair quando quiser. 😉😀',
            disableBeacon: true
          },
        ]
    
      };
    }
  
  
    render() {
      const { run, steps } = this.state;
  
      return (
        <div id="tour">
          <Joyride
            continuous={true}
            run={run}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            steps={steps}
            autostart={true}
          />
        </div>
      )
    }
  }

export default (AppTour);