import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import api from "../../services/api";
import Aside from "../Components/aside/index";
import "./styles.css";
import MaterialDatatable from "material-datatable";
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, BlobProvider, Font } from '@react-pdf/renderer';
import { CSVLink, CSVDownload } from "react-csv";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import SearchIcon from '@material-ui/icons/Search';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import { style } from "@material-ui/system";
import domtoimage from 'dom-to-image';
import FileSaver from 'file-saver';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

var dateFormat = require('dateformat');



const styles = StyleSheet.create({
  table: { display: "table", width: "100%" },
  tableRow: { paddingTop: 10, paddingBottom: 10, margin: "auto", flexDirection: "row", width: "100%", display: "flex", justifyContent: "space-around", borderStyle: "solid", borderWidth: 0.5, borderLeft: '0', borderRight: '0', borderColor: "#cecece" },
  tableRowHeader: { paddingTop: 10, paddingBottom: 10, margin: "auto", flexDirection: "row", width: "100%", display: "flex", justifyContent: "space-around" },
  tableCol: { textAlign: "left", display: "flex", alignItems: "center" },
  tableCell: { fontSize: 7, display: "flex", textAlign: "left", justifyContent: "center", width: "60px", margin: "5px" },
  tableCellb: { fontSize: 6, textAlign: "left", display: "flex", justifyContent: "center", width: "60px", margin: "5px", fontWeight: "700" },
  header: { fontSize: 4, height: "50px", color: "#555" },
  heading: { height: "150px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontSize: 50, color: "#555" },
  page: { paddingTop: "20px", paddingBottom: 20 },
  smallText: { fontSize: 12 }
});

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_token: localStorage.getItem('api_token'),
      schedules: [],
      vehicles: [],
      drivers: [],

      datarows:[],
      rowswithfilter: [],

      date_initial: dateFormat(new Date().toLocaleDateString("en-us"), "yyyy-mm-dd"),
      date_end: dateFormat(new Date().toLocaleDateString("en-us"), "yyyy-mm-dd"),

      ModalfullScreen: false,
      ModalOpen: false,
      ModalData: [],
      tableLoading: true,

      initial_date: dateFormat(getFirstDayOfMounth().toLocaleDateString("en-us"), "yyyy-mm-dd"),
      end_date: dateFormat(getLasDayOfMounth().toLocaleDateString("en-us"), "yyyy-mm-dd"),
      dataAtual: dateFormat(new Date().toLocaleDateString("en-us"), "yyyy-mm-dd"),
      print: [],
      printwithfilter: [],

      showPngContent: false,
      showPdfDownloadButton: false,

      vehiclesFilter: [],
      driversFilter: [],
      checked: true,
    };
  }

  componentDidMount = () => {
    const { api_token, date_initial, date_end } = this.state;

    this.handleSearchReservations();
    try {
      api.get(`/vehicles?api_token=${api_token}`).then(res => {
          var arr = [];
          res.data.vehicles.map((item, i) => {
            arr[i] = item.name;
          });
          this.setState({ vehicles: res.data.vehicles, vehiclesFilter: arr });
        })

      api.get(`/user/drivers?api_token=${api_token}`).then(res => {
        var arr = [];
        res.data.drivers.map((item, i) => {
          arr[i] = item.name
        });
        this.setState({ drivers: res.data.drivers, driversFilter: arr })
      })
    } catch (error) {}
  };

  handleSearchReservations = () => {
    const { api_token, initial_date, end_date } = this.state;

    try {
      api.post(`/vehicles-reservation/filter?api_token=${api_token}`, {date_initial: initial_date, date_end: end_date}).then(res => {
        const data = res.data.vehicles_reservation;
        const sc = [];
        Object.entries(data).map(([key, value]) => {
          {
            var k = key;
            Object.entries(value).map(([i, val]) => {
              var name = val['user']['name'];
              var vehicle = val['vehicle']['name'];
              var schedulingname = val['scheduling_user']['name'];

              val.username = name;
              val.vehicle = vehicle;
              val.schedulingname = schedulingname;
              sc[k] = val;
            });
          }
        });
        setTimeout(() => {
          this.setState({ schedules: sc, datarows: sc, print: sc, tableLoading: false, printwithfilter: sc });
          this.handleAttFilter();
        }, 100);
      })
      .catch(err => {
        toast.error('Algo deu errado na conexão😕 Tente novamente!');
        this.setState({ tableLoading: false });
      })
    } catch (error) {
      this.setState({ tableLoading: false });
      toast.error('Algo deu errado na conexão😕 Tente novamente!');
    }
  }

  handleAttFilter = () => {
    const { vehiclesFilter, driversFilter, schedules } = this.state;

    var data = [];
    schedules.map((item, i) => {
      if(vehiclesFilter.includes( item.vehicle ) && driversFilter.includes( item.username)) {
        data.push(item);
      }
    });
    setTimeout(() => {
      this.setState({ datarows: data });
    }, 100);
  }

  handleFilterVehicle = (e) => {
    const { vehiclesFilter } = this.state;

    if(vehiclesFilter.includes(e.target.value)){
      var array = [];
      vehiclesFilter.filter(function(item, i) {
        if(item !== e.target.value ){
          array.push(item);
        }
        //return item !== dr;
      });
      this.setState({ vehiclesFilter: array });
    }else {
      this.setState({ vehiclesFilter:[...this.state.vehiclesFilter, e.target.value] });
    }

    setTimeout(() => {
      this.handleAttFilter();
    }, 50);
  }

  handleFilterDrivers = (e) => {
    const { driversFilter } = this.state;

    if(driversFilter.includes(e.target.value)){
      var array = [];
      driversFilter.filter(function(item, i) {
        if(item !== e.target.value ){
          array.push(item);
        }
      });
      this.setState({ driversFilter: array });
    }else {
      this.setState({ driversFilter:[...this.state.driversFilter, e.target.value]});
    }
    setTimeout(() => {
      this.handleAttFilter();
    }, 50);
  }

  handleRowClick = (e) => {
    const data = e;
    this.setState(state => ({
      ModalOpen: !state.ModalOpen,
      ModalData: data
    }));
  }

  handleInitiaDateSelected = (e) => {
    if(!e.target.value){
      this.setState({ initial_date: dateFormat(new Date().toLocaleDateString("en-us"), "yyyy-mm-dd") });
      return;
    } else {
      this.setState({ initial_date: e.target.value });
    }
  }

  handleEndDateSelected = (e) => {
    if(!e.target.value){
      this.setState({ end_date: dateFormat(new Date().toLocaleDateString("en-us"), "yyyy-mm-dd") });
      return;
    }
    this.setState({ end_date: e.target.value });
  }

  handleVehicleSelected = (e) => {
    console.log(e.target.value);
  }

  handleDriverSelected = (e) => {
    console.log(e.target.value);
  }


  handleModalClose = e => {
    this.setState(state => ({
        ModalOpen: !state.ModalOpen,
        showPngContent: false
    }));
  }  

  handleExportToPng = () => {
    this.setState({ showPngContent: true });
    setTimeout(() => {
      try {
        domtoimage.toBlob(document.getElementById('scheduling-content'))
        .then(function (blob) {
            window.saveAs(blob, 'siscar.png');
        })
        .catch(erro => {
          toast.error('Algo deu errado na conexão😕 Tente atualizar a página!');
        });
      } catch (error) {
        toast.error('Algo deu errado na conexão😕 Tente atualizar a página!');        
      }
    }, 25);
  }

  handleGeneratePdf = () => {
    this.setState({ showPdfDownloadButton: true });
    setTimeout(() => {
      try { 
        var el = document.getElementsByClassName('pdf-download-button')[0];
        el.setAttribute("id", "pdf-download-button");
        var click_ev = document.createEvent("MouseEvents");
        click_ev.initEvent("click", true /* bubble */, true /* cancelable */);
        document.getElementById("pdf-download-button").dispatchEvent(click_ev);
      } catch (error) {
        toast.error('Algo deu errado😕 Tente novamente!');
      }
      this.setState({ showPdfDownloadButton: false });
    }, 1500);
  }

  render() {
    const columns = [
      { name: "Id", field: "id", options: { filter: false } },
      { name: "Usuário", field: "username" },
      { name: "Carro", field: "vehicle" },
      { name: "Data", field: "date", options: { filter: false } },
      { name: "Motivo", field: "reason", options: { filter: false } },
      { name: "Saída", field: "input", options: { filter: false } },
      { name: "Chegada", field: "output", options: { filter: false }}
    ];

    //const data = this.state.options['data']['rows'];
   //const data = this.state.printwithfilter;

    const csvData = [
      ["firstname", "lastname", "email"],
      ["Ahmed", "Tomi", "ah@smthing.co.com"],
      ["Raed", "Labes", "rl@smthing.co.com"],
      ["Yezzi", "Min l3b", "ymin@cocococo.com"]
    ];

    const options = {
      filterType: "checkbox",
      responsive: "scroll",
      selectableRows: false,
      onRowClick: (rowObject) => this.handleRowClick(rowObject)
    };

    const ModalContentData = this.state.ModalData;

    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.heading}>
              <Text>Relatório SisCar</Text>
              <Text style={styles.smallText}>{this.state.initial_date.split('-').reverse().join('/')} &nbsp;-&nbsp; {this.state.end_date.split('-').reverse().join('/')}</Text>
          </View>
          <View style={styles.table, styles.header}>
              <View style={styles.tableRowHeader}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellb}>Nome</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellb}>Veículo</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellb}>Data</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellb}>Hora inicial</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellb}>Hora final</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellb}>Saída</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellb}>Chegada</Text>
                </View>
              </View>
          </View>
          <View style={styles.table}>
            {
              this.state.datarows != null ?
                this.state.datarows.map((items, key) => {
                  return (
                      <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{items.username}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{items.vehicle}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{items.date}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{items.hour_initial}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{items.hour_end}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{items.input}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{items.output}</Text>
                        </View>
                      </View>
                  )
                })
              : null
            }
          </View>
        </Page>
      </Document>
    );

    const PdfExportButton = () => (
      <div>
        <PDFDownloadLink document={<MyDocument />} fileName="relatorio-siscar.pdf" className="pdf-download-button">
          {({ blob, url, loading, error }) => (loading ?
            <Button variant="contained" color="primary" disabled ><PictureAsPdfIcon />&nbsp;&nbsp;Exportar em pdf&nbsp;&nbsp;</Button> :
            <Button variant="contained" color="primary"><PictureAsPdfIcon />&nbsp;&nbsp;Exportar em pdf&nbsp;&nbsp;</Button>)}
        </PDFDownloadLink>
      </div>
    );

    const CsvExportButton = () => (
      <div>
        <CSVLink data={csvData}>
          <Button variant="contained" color="primary"><DataUsageIcon />&nbsp;&nbsp;Exportar em CSV&nbsp;&nbsp;</Button>
        </CSVLink>
      </div>
    );

    return (
      <div className="container todo report">
        <Aside />
        <div className="calendar">
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
              />
          <ToastContainer />
          <div className="calendar-table">
              <div className={["report-table-loading", this.state.tableLoading === true ? null : "disabled"].join(' ')}>
                <CircularProgress />
              </div>
              <div id="filter-controls">
                <div className="paper-controls-flex controls-filter">
                  <div className="datepickers-groups">
                    <TextField id="date" label="Data inicial" type="date" defaultValue={this.state.initial_date}
                      onChange={this.handleInitiaDateSelected}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField id="date" label="Data final" type="date" defaultValue={this.state.end_date}
                      onChange={this.handleEndDateSelected}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="dropdown-group">

                    <div className="dropdown-check">
                        <span className="filter-name">Veiculos</span>
                        <div className="dropdown-content">
                          {
                            this.state.vehicles.map((vehicle, key) => 
                            <FormControlLabel
                              control={
                                <Checkbox defaultChecked={this.state.checked} color="primary" value={vehicle.name} onChange={this.handleFilterVehicle}/>
                              }
                              label={vehicle.name}
                            />
                              //<span><input onChange={this.handleFilterVehicle} type="checkbox" checked="checked" id={["vehicle", key].join('')} name={key} value={vehicle.name} /><label htmlFor={["vehicle", key].join('')}>{vehicle.name}</label></span>
                            )
                          }
                        </div>
                    </div>
                    <div className="dropdown-check">
                        <span className="filter-name">Condutores</span>
                        <div className="dropdown-content">
                          {
                            this.state.drivers.map((driver, key) => 
                            <FormControlLabel
                            control={
                              <Checkbox defaultChecked={this.state.checked} color="primary" value={driver.name} onChange={this.handleFilterDrivers}/>
                            }       
                            label={driver.name}
                            />
                              //<span><input onChange={this.handleFilterDrivers} type="checkbox" checked="checked" id={["driver", key].join('')} name={key} value={driver.name} /><label htmlFor={["driver", key].join('')}>{driver.name}</label></span>
                            )
                          }
                        </div>
                  </div>
                  </div>
                </div>
                <div className="paper-controls-flex">
                <Tooltip TransitionComponent={Zoom} title="Realizar busca com filtros" placement="bottom">
                  <Button className="search-button" variant="contained" color="primary" onClick={this.handleSearchReservations}><SearchIcon />&nbsp;&nbsp;Aplicar filtros&nbsp;&nbsp;</Button>
                </Tooltip>
                <Tooltip TransitionComponent={Zoom} title="Salvar todos os agendamentos buscados" placement="bottom">
                  <Button className="export-pdf-button" variant="contained" color="primary" onClick={this.handleGeneratePdf}><PictureAsPdfIcon />&nbsp;&nbsp;Exportar em pdf&nbsp;&nbsp;</Button>
                </Tooltip>
                  <div className="pdf-btn" >
                    {
                      this.state.showPdfDownloadButton ?
                        <PdfExportButton />
                      : null
                    }
                  </div>
                </div>
              </div>
              <MaterialDatatable
                title={"Relatório dos agendamentos"}
                data={this.state.datarows}
                columns={columns}
                options={options}
              />
          </div>
        </div>


        <div id="reportViewData">
                <Dialog
                    fullScreen={this.state.ModalfullScreen}
                    open={this.state.ModalOpen}
                    onClose={this.handleModalClose}
                    aria-labelledby="responsive-dialog-title"
                    >
                    <span className="close-imagemodal" >
                      <IconButton aria-label="Search" color="inherit" className="toogleButton close-task" onClick={this.handleModalClose}>
                        <CloseIcon />
                      </IconButton>
                    </span>
                    <DialogTitle id="responsive-dialog-title">{ModalContentData.username}</DialogTitle>
                    <DialogContent className="scheduling-content" >
                      <p>Motorista: <b>{ModalContentData.username}</b></p>
                      <p>Data: <b>{ModalContentData.date}</b></p>
                      <p>Veículo: <b>{ModalContentData.vehicle}</b></p>
                      <p>Motivo: <b>{ModalContentData.reason}</b></p>
                      <p>Saída planejada: <b>{ModalContentData.hour_initial}</b></p>
                      <p>Chegada Planejada: <b>{ModalContentData.hour_end}</b></p>
                      <p>Hora da saída: <b>{ModalContentData.input}</b></p>
                      <p>Hora da chegada: <b>{ModalContentData.output}</b></p>
                      {
                        ModalContentData.input_image != null ?
                        <p>Imagem da saída: <b><a href={`https://siscar-api.iteva.org.br/api/vehicles-reservation/images/${ModalContentData.id}/${ModalContentData.input_image}?api_token=${this.state.api_token}`} target="blank">Vizualizar</a></b></p>
                        : null
                      }
                      {
                        ModalContentData.output_image != null ?
                        <p>Imagem da chegada: <b><a href={`https://siscar-api.iteva.org.br/api/vehicles-reservation/images/${ModalContentData.id}/${ModalContentData.output_image}?api_token=${this.state.api_token}`} target="blank">Vizualizar</a></b></p>
                        : null
                      }
                      <br></br><br></br>

                      <p>*Quem fez a reserva: <b>{ModalContentData.schedulingname}</b></p>
                      <p>*Quando foi feita a reserva: <b>{ModalContentData.scheduling_date}</b></p>
                    </DialogContent>
                    <DialogActions className="report-modal-actions">
                      {/*<Button variant="contained" color="primary" onClick ><PictureAsPdfIcon />&nbsp;&nbsp;Salvar em pdf&nbsp;&nbsp;</Button>*/}
                      <Button variant="contained" color="primary" onClick={this.handleExportToPng} ><AddPhotoAlternateIcon />&nbsp;&nbsp;Salvar em png&nbsp;&nbsp;</Button>
                    </DialogActions>
                    <DialogActions>
                        <Button size="large" color="primary" aria-label="Add" onClick={this.handleModalClose} >&nbsp;&nbsp;Fechar&nbsp;&nbsp;</Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div id="scheduling-content" className={this.state.showPngContent === true ? "active" : "disabled"}>
              <p>Motorista: <b>{ModalContentData.username}</b></p>
              <p>Data: <b>{ModalContentData.date}</b></p>
              <p>Veículo: <b>{ModalContentData.vehicle}</b></p>
              <p>Motivo: <b>{ModalContentData.reason}</b></p>
              <p>Saída planejada: <b>{ModalContentData.hour_initial}</b></p>
              <p>Chegada Planejada: <b>{ModalContentData.hour_end}</b></p>
              <p>Hora da saída: <b>{ModalContentData.input}</b></p>
              <p>Hora da chegada: <b>{ModalContentData.output}</b></p>
              {
                ModalContentData.input_image != null ?
                <div>Imagem da saída: <img src={`https://siscar-api.iteva.org.br/api/vehicles-reservation/images/${ModalContentData.id}/${ModalContentData.input_image}?api_token=${this.state.api_token}`} /></div>
                : null
              }
              <br></br>
              {
                ModalContentData.output_image != null ?
                <div>Imagem da chegada:<img src={`https://siscar-api.iteva.org.br/api/vehicles-reservation/images/${ModalContentData.id}/${ModalContentData.output_image}?api_token=${this.state.api_token}`} /></div>
                : null
              }
              <br></br><br></br>

              <p>*Quem fez a reserva: <b>{ModalContentData.schedulingname}</b></p>
              <p>*Quando foi feita a reserva: <b>{ModalContentData.scheduling_date}</b></p>
            </div>
      </div>
    );
  }
}

function getLasDayOfMounth() {
  var today = new Date();
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  return lastDayOfMonth;
}

function getFirstDayOfMounth() {
  var today = new Date();
  var firstayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  return firstayOfMonth;
}


export default withRouter(Report);
