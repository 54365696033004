import React from 'react';
import './App.css';
import Routes from "./routes";
import "./styles/global";

const theme = localStorage.getItem("darkmode");

if(theme == 'true' ){
    import("./styles/darkmode.css")
}

const App = () => <Routes />;

export default App;