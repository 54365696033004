import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import authValidation from "./services/validation";
import SignIn from "./pages/SignIn";
import Schedule from "./pages/Schedule";
import Report from './pages/Report';
import carLoading from './assets/images/car.gif';
import ImagError from './assets/images/404.jpg';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isAuthenticated: false,
    }
    this.componentDidMount = this.componentDidMount.bind(this);
  }


  componentDidMount = async e => {
    if(!localStorage.getItem('api_token') || !localStorage.getItem('name')){
      window.location.replace("/login");
    }

    try {
      const auth = await authValidation();
      if(auth === false ){
        window.location.replace("/login");
      }else {
        this.setState({ loading: auth, isAuthenticated: auth });
      }
    } catch (error) {
      window.location.replace("/login");
    }
  }


  render() {
    const { component: Component, ...rest } = this.props
    return (
      <Route
        {...rest}
        render={props =>
          this.state.isAuthenticated === true ? (
            <Component {...props} />
          ) : (
              this.state.loading ? (
                <div className="wp">
                  <div><img src={carLoading} alt="loading..." /></div>
                  <div className="text">Carregando...</div>
                </div>
              ) : (
                    <Redirect to={{ pathname: '/app', state: { from: this.props.location } }} />
                  )
          )
        }
      />
    )
  }
}


const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/*
      <Route exact path="/" component={SignIn} />
      */}
      <PrivateRoute exact path="/" component={Schedule} />
      <Route exact path="/login" component={SignIn} />
      <PrivateRoute path="/app" component={Schedule} />
      <PrivateRoute path="/relatorio" component={Report} />
      <Route path="*" component={() => <div className="error-message"><h1>A página que você procura não existe! :(</h1> <a href="/login">Tente fazer login ...</a><img src={ImagError} alt="Page error illustration" /></div>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;