import axios from "axios";
import { getToken } from "./auth";


const api = axios.create({
  baseURL: "https://siscar-api.iteva.org.br/api",
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'JWT fefege...',
    'Access-Control-Allow-Origin': "*",
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, Access-Control-Allow-Origin",
  },
  mode: 'no-cors',
});


/*
const api = axios.create({
  baseURL: "https://cors-anywhere.herokuapp.com/http://agencar.iteva.org.br/api",
  headers: {
    'Content-Type': 'multipart/form-data',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Authorization': 'JWT fefege...',
    'Access-Control-Allow-Origin': "*",
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, Access-Control-Allow-Origin",
  },
  mode: 'no-cors',
});
*/

/*
const api = axios.create({
  baseURL: "http://agencar.iteva.org.br/api",
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'JWT fefege...',
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': 'http://agencar.iteva.org.br/api'}
});
*/

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;