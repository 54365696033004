import styled from "styled-components";
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: rgb(29,25,124);
  background: linear-gradient(325deg, rgba(29,25,124,0.7007003484987745) 0%, rgba(22,142,205,0.7035014689469538) 100%);
`;

export const Form = styled.form`
  position: absolute;
  height: 550px;
  width: 450px;
  width: 400px;
  position: absolute;
  height: 550px;
  width: 450px;
  border-radius: 2px;
  background: #fff;
  padding: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-shadow: 0px 0px 27px -3px rgba(0,0,0,0.57);
  -moz-box-shadow: 0px 0px 27px -3px rgba(0,0,0,0.57);
  box-shadow: 0px 0px 27px -3px rgba(0,0,0,0.57);
  justify-content: center;

  img {
    width: 100px;
    margin: 10px 0 40px;
  }

  .line {
    position: absolute;
    height: 50px;
    width: 6px;
    background-color: #3f51b5;
    top: 40px;
    left: 0;
  }

  .formLabel {
    position: absolute;
    color: #3f51b5;
    top: 43px;
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    color: #ff3333;
    margin-bottom: 15px;
    border: 1px solid #ff3333;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
  input {
    color: #777 !important;
    width: 100%;
  }
  button {
    color: #fff;
    font-size: 16px;
    height: 56px;
    border: 0;
    border-radius: 5px;
    width: 100%;
  }
  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
  }
  a {
    font-size: 16;
    font-weight: bold;
    color: #999;
    text-decoration: none;
  }
  .MuiFormControl-marginNormal {
    width: 100%;
  }
`; 
