import React, { Component } from "react";
import '../forms.css';
import api from "../../../services/api";
import { withRouter } from "react-router-dom";


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CircularProgress from '@material-ui/core/CircularProgress';

import InputMask from 'react-input-mask';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import { toast } from 'react-toastify';

//var dateFormat = require('dateformat');

class NewVehicle extends Component {
    state = {
        open: true,
        fullScreen: false,
        api_token: localStorage.getItem('api_token'),
        username: localStorage.getItem('name'),
        progress: true,
        carname: '',
        carplate: '',
        formValidation: false,

        //BUTTON LOADING
        setSuccess: false,
        setLoading: false,
    }


    //CRUD EVENTS
    handleNewVehicle = e => {
        const { carname, carplate} = this.state;
        if(!carname || !carplate ){
            this.setState({ formValidation: true});
            return false;
        }
        this.setState({ setSuccess: false, setLoading: true, formValidation: false });
        try {
            api.post('/vehicles?api_token=' + this.state.api_token, {name: carname, license_plate: carplate})
            .then ( res => {
                if(res.data.error == true){
                    toast.warning('🚗 Algo deu errado, tente atualizar a página!');
                    this.setState({ setLoading: false });
                    return false;
                }
                toast.success('🚗 Veículo cadastrado com sucesso!😉');
                this.setState({ setSuccess: true, setLoading: false});
            })
        }catch (err){
            this.setState({
                setSuccess: false, setLoading: true,
            });
            toast.error('Algo deu errado!😕 Tente novamente em alguns minutos!');
        }
    }


    handleClose = async e => {
        this.setState(state => ({
            open: !state.open
        }));
    }

    render(){
        return (
            <div id="newvehicles">
                {/*
                <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
                <ToastContainer />
                */}
                <Dialog
                    fullScreen={this.state.fullScreen}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                    >
                    <DialogTitle id="responsive-dialog-title">{"Cadastrar novo veículo"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                            <form autoComplete="off" className="formSchedule">
                                <TextField id="standard-full-width" label="Nome" placeholder="Escreva um nome para o veículo" onChange={(e) => this.setState({ carname: e.target.value })} InputLabelProps={{shrink: true,}} />
                                <InputMask mask="****-****" onChange={(e) => this.setState({ carplate: e.target.value })}>
                                    {() => <TextField label="Placa do veículo" />}
                                </InputMask>
                            </form>
                    </DialogContent>
                    {this.state.formValidation === true ? 
                        <DialogActions>
                            <span className="spanerror">Preencha corretamente todos os campos</span>
                        </DialogActions>
                    : null}
                    <DialogActions>
                        <div className="wrapper">
                            <Fab variant="extended" size="large" color="primary" aria-label="Add" onClick={this.handleNewVehicle} className={["btn-send",this.state.setSuccess === true ? 'buttonSuccess' : ''].join(" ")} disabled={this.state.setLoading}>Cadastrar</Fab>
                            {this.state.setLoading === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
                        </div>
                        <Button onClick={this.handleClose} color="primary">
                        Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withRouter(NewVehicle);