import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import {
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";

import CircularProgress from '@material-ui/core/CircularProgress';


import api from "../../services/api";
import { login } from "../../services/auth";

import './styles.css';

import { Form, Container } from "./styles";
import IconButton from '@material-ui/core/IconButton';
import EyeIcon from '@material-ui/icons/RemoveRedEye';

import ScripleLogo from '../../assets/images/scriple.png';
import ItevaLogo from '../../assets/images/logoTopoBranco.png';

class SignIn extends Component {
  state = {
    email: "",
    password: "",
    name: '',
    registerEmail: "",
    registerPassword: "",
    error: "",
    errorSignup: "",
    cnh: "",
    isToggleOn: false,
    showRegister: false,
    showAnime: false,

    setSuccess: false,
    setLoading: false,

    setSuccessSignup: false,
    setLoadingSignup: false,

    viewPassword: false,
  };

  handleButtonClick = e => {
    if (this.state.setLoading === false) {
      this.setState({ setSuccess: false, setLoading: true});
      setTimeout(() => {
        this.setState({ setSuccess: true, setLoading: false});
      }, 2000);
    }
  }

  handleSignIn = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    this.setState({ setSuccess: false, setLoading: true});
    if (!email || !password) {
      this.setState({ error: "Preencha todos os dados para continuar!", setSuccess: false, setLoading: false });

    } else {
      var config = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        withCredentials: false
      }
      try {
        const response = await api.post("/login", { email, password }, config);
        login(response.data.token);
        const dataToken = response.data.user.api_token;
        const dataName = response.data.user.name;
        localStorage.setItem('api_token', dataToken);
        localStorage.setItem('name', dataName);
        if(localStorage.getItem('firstaccess') != 'false' ) {
          localStorage.setItem('firstaccess', true);
        }
        if(localStorage.getItem('siscar-paper') != 'false' ) {
          localStorage.setItem('siscar-paper', true);
        }
        this.setState({ setSuccess: true, setLoading: false});
        this.props.history.push("/app");
      } catch (err) {
        this.setState({
          setSuccess: false, setLoading: false,
          error:
            "Houve um problema com o login."
        });
      }
    }
  };

    handleChange = async e => {
      this.setState(state => ({
        isToggleOn: !state.isToggleOn
      }));
      if(this.state.isToggleOn === true){

      }
    }

    handleViewPass = e => {
      e.preventDefault();
      this.setState(state => ({
        viewPassword: !state.viewPassword
      }));
    }

  render() {
    return (
      <Container>
        <Form onSubmit={this.handleSignIn}>
          <div className={['fullAnimation ', this.state.showAnime ? 'anime' : ' '].join(" ")}></div>
          <span className="line"></span><span className="formLabel">SisCar</span>
          <TextField
              id="standard-email-input"
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              margin="normal"
              onChange={e => this.setState({ email: e.target.value })}
          />
          <div className="wrapper">
            <IconButton aria-label="Search" color="inherit" className={["view-password", this.state.viewPassword ? '' : 'off'].join(' ')} onClick={this.handleViewPass}>
                <EyeIcon />
            </IconButton>
            <TextField
              id="standard-password-input"
              label="Password"
              type={this.state.viewPassword ? 'text' : 'password'}
              autoComplete="current-password"
              margin="normal"
              onChange={e => this.setState({ password: e.target.value })}
            />
          </div>
          <div className="wrapper">
              <span className="spanerror">{this.state.error}</span>
          </div>
          <div className="wrapper">
              <Button type="submit" variant="contained" color="primary" className={this.state.setSuccess === true ? 'buttonSuccess' : ''} disabled={this.state.setLoading}>Entrar</Button>
              {this.state.setLoading === true ? <CircularProgress size={5} className="buttonProgress" /> : null}
          </div>

          <div className="form-footer">
            <img src={ScripleLogo} alt="Scriple logo" />
            <img src={ItevaLogo} alt="Iteva logo" />
          </div>
        </Form>
      </Container>
    );
  }
}
export default withRouter(SignIn);