import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import api from "../../../services/api";

import notFoundImage from '../../../assets/images/not-found.png';

import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import HighlightIcon from '@material-ui/icons/Highlight';
import PlaceIcon from '@material-ui/icons/Place';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ClearIcon from '@material-ui/icons/Clear';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import CalendarIcon from '@material-ui/icons/CalendarToday';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Menu from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';

import Vehicles from "../../Crudreservation/vehicles";
import NewVehicle from "../../Crudreservation/newvehicles";
import NewUser from "../../Crudreservation/newuser";
import Users from "../../Crudreservation/users";
import AppTour from "../../Components/tour/apptour";
import ReportTour from "../../Components/tour/reporttour";


import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Scrollbars } from 'react-custom-scrollbars';


class Aside extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showVehiclesModal: false,
        showUsersModal: false,
        showNewVehiclesModal: false,
        showNewUserModal: false,
        username: localStorage.getItem('name'),
        api_token: localStorage.getItem('api_token'),
        toogle: false,
        admin: false,
        apptour: false,
        reporttour: false,
        redirect: false,
        redirectPath: null,
        path: window.location.pathname,
        paper: localStorage.getItem('siscar-paper'),

        steps: [
          'a',
          'b',
          'c',
          'c',
          'c',
        ],

        haveNotifications: false,
        notifications: [],
      };
      this.handleVehicles = this.handleVehicles.bind(this);
      this.handleNewVehicles = this.handleNewVehicles.bind(this);
      this.handleNewUser = this.handleNewUser.bind(this);
      this.handleUsers = this.handleUsers.bind(this);
      this.setNotifications = this.setNotifications.bind(this);
    }
  
    componentDidMount = async e => {
      this.setNotifications();

      try {
        var bodyRect = document.body.getBoundingClientRect(),
        elemRect = document.getElementById('report-button').getBoundingClientRect(),
        offset   = elemRect.top - bodyRect.top;
        offset = offset - 110;
        document.getElementById('new-feature-card').style.top = `${offset}px`;
      } catch (error) {
        console.error('Element error');
      }

      try {
        api.get('/user/info?api_token=' + this.state.api_token)
        .then( res => {
          if(res.data.user_info.type_user.toLowerCase() === 'administrator'){
            this.setState({ admin: true });
            console.clear();
          }else {
            this.setState({ admin: false });
            if(this.state.path == '/relatorio') {
              window.location.replace("/");
            }
            console.clear();
          }
          setTimeout(() => {
            if(localStorage.getItem('report-tour') == 'true'){
              this.setState({ reporttour: true });
              setTimeout(() => {
                localStorage.setItem('report-tour', false);
              }, 10000);
            }
          }, 1500);
        })
      } catch (error) {
        console.clear();
        return false;
      }
    }
  
    handleMenuToogle() {
      var menu = document.getElementById("avatarmenuaction");
      menu.classList.add("active");
    }
  
    handleChangeTheme() {
      var theme = localStorage.getItem('darkmode');
      if(theme === 'true'){
        localStorage.setItem('darkmode', false);
        window.location.reload();
      }else {
        localStorage.setItem('darkmode', true);
        window.location.reload();
      }
    }
  
    handleLogout = e => {
      try{
        api.get('/user/logout?api_token=' + this.state.api_token)
        .then( res => {
          localStorage.removeItem('api_token');
          return window.location.replace("/");
        })
        .catch( err => {
          localStorage.removeItem('api_token');
          return window.location.replace("/");
        });
  
      }catch (err){
        localStorage.removeItem('api_token');
        return window.location.replace("/");
      } 
    }
  
    handleVehicles() {
      if(this.state.admin === false ){
        return false;
      }
      const modal = document.getElementById('vehicles');
  
      if(modal === null){
        this.setState(state => ({ showVehiclesModal: !state.showVehiclesModal })); 
      }else {
        this.setState(state => ({ showVehiclesModal: !state.showVehiclesModal }));
        setTimeout(() => { this.setState(state => ({ showVehiclesModal: !state.showVehiclesModal })); }, 10);
      }
    }
  
    handleNewVehicles() {
      if(this.state.admin === false ){
        return false;
      }
      const modal = document.getElementById('newvehicles');
  
      if(modal === null){
        this.setState(state => ({ showNewVehiclesModal: !state.showNewVehiclesModal })); 
      }else {
        this.setState(state => ({ showNewVehiclesModal: !state.showNewVehiclesModal }));
        setTimeout(() => { this.setState(state => ({ showNewVehiclesModal: !state.showNewVehiclesModal })); }, 10);
      }
    }
  
    handleNewUser() {
      if(this.state.admin === false ){
        return false;
      }
      const modal = document.getElementById('newuser');
  
      if(modal === null){
        this.setState(state => ({ showNewUserModal: !state.showNewUserModal })); 
      }else {
        this.setState(state => ({ showNewUserModal: !state.showNewUserModal }));
        setTimeout(() => { this.setState(state => ({ showNewUserModal: !state.showNewUserModal })); }, 10);
      }
    }
  
    handleUsers() {
      if(this.state.admin === false ){
        return false;
      }
      const modal = document.getElementById('users');
  
      if(modal === null){
        this.setState(state => ({ showUsersModal: !state.showUsersModal })); 
      }else {
        this.setState(state => ({ showUsersModal: !state.showUsersModal }));
        setTimeout(() => { this.setState(state => ({ showUsersModal: !state.showUsersModal })); }, 10);
      }
    }
  
    handleToggle = (e) => {
      var pick = document.getElementsByClassName("toogle");
      var pickid = document.getElementById("pick");
      if(pick.length > 0){
        pickid.classList.remove("toogle");
      }else {
        pickid.classList.add("toogle");
      }
    };
  
    handleMenuExit = (e) => {
      var menu = document.getElementById("avatarmenuaction");
      setTimeout(() => {
        menu.classList.remove("active");
      }, 1000);
    }
  
    isDis(){
      try {
        api.get('/user/info?api_token=' + this.state.api_token)
        .then( res => {
          if(res.data.user_info.type_user.toLowerCase() === 'administrator'){
            this.setState({ admin: false });
          }else {
            this.setState({ admin: true })
          }
        })
      } catch (error) {
        return false;
      }
    }
  
    handleShowAppTour = (e) => {
      this.closeNotify();
      if(e == "/relatorio") {
        this.setState({ reporttour: true });
      }else if(e == "/app" || e == "/"){
        this.setState({ apptour: true });
      }else {
        return false;
      }
    }

    setNotifications = async () => {
      const response = await api.get(`/notifications?api_token=${this.state.api_token}`);
      if(!response.data) console.log('error');
      if(response.data.notifications.length <= 0){
        this.setState({ haveNotifications: false });
      }else {
        this.setState({ haveNotifications: true });
      }
      this.setState({ notifications: response.data.notifications });
      this.setNotificationsWrapperHeight();
    }

    setNotificationsWrapperHeight() {
      var len = document.getElementsByClassName('notify-content').length;
      if( len === 3) document.getElementById('notify-list').classList.add('one');
      if( len === 4) document.getElementById('notify-list').classList.add('two');
    }
  
    setRedirect = (e) => {
      this.setState({ redirectPath: e, redirect: true });
    }
  
    renderRedirect = () => {
      if (this.state.redirect) {
        return <Redirect to={this.state.redirectPath}/>
      }
    }

    clickOnNotify = (e, date, key) => {
      if(e.contains('clear-notify') || e.contains('clear-box')) {
        return;
      }
      this.props.setNotifyFunction(date, key);
    }

    openNotifyWrapper() {
      const notify = document.getElementById('notify-wrapper');
      if(notify.classList.contains('active')) {
        notify.classList.remove('active');
      }else {
        notify.classList.add('active');
      }
    }

    closeNotify() {
      const notify = document.getElementById('notify-wrapper');
      notify.classList.remove('active');
    }

    clearNotify = async (e) => {
      const response = await api.get(`/notifications/view/${e}?api_token=${this.state.api_token}`);

      if(response.data.result == true) {
        document.getElementById(`notification${e}`).classList.add('removed');
        setTimeout(() => {
          removeElement(`notification${e}`);
          this.setNotificationsWrapperHeight();
        }, 500);
      }
    }

    render (){
  
      return(
        <div className="aside">
          {this.renderRedirect()}

          <div className="notify-wrapper" id="notify-wrapper">
            <div className="notify-wrapper-header">
              <p>{this.state.notifications.length != 0 ? this.state.notifications.length : 'SEM'} NOTIFICAÇÕES</p>
                <IconButton aria-label="delete" onClick={this.openNotifyWrapper}>
                  <ClearIcon fontSize="small" />
                </IconButton>
            </div>
            <div className="notify-list" id="notify-list">
              {
                this.state.notifications.length == 0 ?
                  <img className="not-found-notify" src={notFoundImage} alt="Sem notificações" />
                : null
              }
              <Scrollbars>
                {
                  this.state.notifications.length != 0 ?                 
                    <div className="notify-content">
                      <div className="notify-line"></div>
                      <div className="content-left-ghost"></div>
                    </div>
                  : null
                }

                {
                  this.state.notifications.map((item, key) => {
                    if(item != null ) {
                      var userName = item.info.user.name.split(' ')[0];
                      return (
                          <div className="notify-content" id={["notification", item.id].join('')} key={key}>
                            <div className="clear-box" >
                              <ClearIcon fontSize="inherit" className="clear-notify" onClick={() => this.clearNotify(item.id)} />
                            </div>
                            <div className="notify-line"></div>
                            <div className="content-left-ghost" onClick={(e) => this.clickOnNotify(e.target.classList, item.info.date, item.info.id)}>
                              <div className="content-left">
                                <div className="notify-body">
                                  <ErrorOutlineIcon /><p><span>{userName}</span> deixou um agendamento em aberto</p>
                                </div>
                                <div className="notify-header">
                                  <p>{item.info.date.split('-').reverse().join('/')}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                      )
                    }
                  })
                }

                {
                  this.state.notifications.length != 0 ?                  
                    <div className="notify-content">
                      <div className="notify-line"></div>
                      <div className="content-left-ghost"></div>
                    </div>
                  : null
                }
              </Scrollbars>
            </div>
          </div>

          <div className="avatarmenuaction" id="avatarmenuaction" >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Olá, {this.state.username}
                </ListSubheader>
              }
            >
              <ListItem button onClick={this.closeNotify, this.handleChangeTheme}>
                <ListItemIcon>
                  <HighlightIcon />
                </ListItemIcon>
                <ListItemText primary="Modo Dark" />
              </ListItem>
              <ListItem button onClick={(e) => this.handleShowAppTour(this.state.path)}>
                <ListItemIcon>
                  <PlaceIcon />
                </ListItemIcon>
                <ListItemText primary="Tour" />
              </ListItem>
              <ListItem button onClick={(e) => this.handleLogout(e)}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </ListItem>
            </List>
          </div>
  
          {
            this.state.path != '/relatorio' ?
              <Toogle handleToggle={this.handleToggle} />
            : null
          }

          <Tooltip TransitionComponent={Zoom} title="Ver todos os agendamentos" placement="right">
            <div>
              <IconButton aria-label="Search" color="inherit" onClick={this.closeNotify, (e) => this.setRedirect('/app')}>
                  <CalendarIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip TransitionComponent={Zoom} title="Listagem de Veículos" placement="right">
            <div>
              <IconButton aria-label="Search" color="inherit" onClick={this.closeNotify, this.handleVehicles} disabled={!this.state.admin} >
                  <DirectionsCarIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip TransitionComponent={Zoom} title="Cadastrar novo Veículos" placement="right">
            <div>
              <IconButton aria-label="Search" color="inherit" onClick={this.closeNotify, this.handleNewVehicles} disabled={!this.state.admin} >
                  <AddIcon className="add-absolute" />
                  <DirectionsCarIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Divider />
          <Tooltip TransitionComponent={Zoom} title="Listagem de Usuários" placement="right">
            <div>
              <IconButton aria-label="Search" color="inherit"  onClick={this.closeNotify, this.handleUsers} disabled={!this.state.admin} >
                  <PersonOutlineIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip TransitionComponent={Zoom} title="Cadastro de Usuários" placement="right">
            <div>
              <IconButton aria-label="Search" color="inherit" onClick={this.closeNotify, this.handleNewUser} disabled={!this.state.admin} >
                  <PersonAddIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip TransitionComponent={Zoom} title="Relatório dos agendamentos" placement="right">
            <div>
              <IconButton id="report-button" aria-label="Search" color="inherit" onClick={this.closeNotify, (e) => this.setRedirect('/relatorio')} disabled={!this.state.admin} >
                  <InsertDriveFileIcon />
              </IconButton>
            </div>
          </Tooltip>

          <IconButton className={["notify", this.state.haveNotifications ? 'active' : ''].join(' ')} id="notify-button" aria-label="Search" color="inherit" onClick={this.closeNotify, this.openNotifyWrapper}>
              <div className="notify-badge"></div>
              {
                this.state.haveNotifications == true ?
                  <NotificationsActiveIcon />
                  : 
                  <NotificationsNoneIcon />
              }
          </IconButton>

            <Fab aria-label="Profile" className="profile" onMouseOver={this.handleMenuToogle} onMouseLeave={this.handleMenuExit}>
              <Avatar className="avatar">{ this.state.username.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'') }</Avatar>
            </Fab>
            {this.state.showVehiclesModal ? <Vehicles /> : null}
            {this.state.showUsersModal ? <Users /> : null}
            {this.state.showNewVehiclesModal ? <NewVehicle /> : null}
            {this.state.showNewUserModal ? <NewUser /> : null}
            {this.state.apptour ? <AppTour /> : null}
            {this.state.reporttour ? <ReportTour /> : null}
            
      </div>
      )
    }
  }

const Toogle = (props) => (
  <IconButton aria-label="Search" color="inherit" className="toogleButton" onClick={(e) => {props.handleToggle(e);}}>
    <Menu />
  </IconButton>
)

function removeElement(id) {
  var elem = document.getElementById(id);
  return elem.parentNode.removeChild(elem);
}
  
export default withRouter(Aside);