import React, { Component } from "react";
import Joyride from 'react-joyride';
import gaguinho from "../../../../assets/images/gaguinho.png";

class ReportTour extends Component {
    constructor(props) {
      super(props);
      this.state = {
        run: true,
        steps: [
          {
            target: '.calendar-table',
            title: 'Listagem',
            content: 'Aqui serão listados todos os agendamentos. 😉',
            locale: { skip: <p aria-label="skip">Fechar tour</p> },
            disableBeacon: true
          },
          {
            target: '.datepickers-groups',
            title: 'Filtros',
            content: 'Aqui você escolhe as datas que quer filtrar os resultados, por padrão já vem com o mês todo como filtro, mas para aplicar este filtro é preciso clickar no botão aplicar filtros.',
            disableBeacon: true
          },
          {
            target: '.dropdown-group',
            title: 'Filtros',
            content: 'Aqui você pode filtrar por veículo e por condutor, apenas selecionando o filtro já será aplicado.',
            disableBeacon: true
          },
          {
            target: '.search-button',
            title: 'Filtros',
            content: 'Clique aqui para aplicar todos os filtros.',
            disableBeacon: true
          },
          {
            target: '.export-pdf-button',
            title: 'Exportar para PDF',
            content: 'Clique aqui para exportar tudo em um arquivo PDF.',
            disableBeacon: true
          },
          {
            target: '.MuiToolbar-gutters',
            title: 'Filtros da tabela',
            content: 'Este menu de ações funciona apenas para o que é vizualizado na tabela, você pode buscar, imprimir e escolher as colunas que quer exibir. Estes filtros não alteram o que será exportado em pdf.',
            disableBeacon: true
          },
          {
            target: 'tr',
            title: 'Dados do agendamento',
            content: 'Clique em uma linha para vizualizar os dados completos do agendamento. Você também poderá exportar os dados com imagens para uma imagem PNG.',
            disableBeacon: true
          },
          {
            target: '.calendar-table',
            title: 'SisCar',
            content: (
              <div>
                <h4>Isso é tu-tu-tudo pessoal, em breve traremos mais funcionalidades bacanas para o SisCar. 🐷</h4>
                <img className="gaguinho-image" src={gaguinho} />
              </div>
            ),
            disableBeacon: true
          },
        ]
    
      };
    }
  
  
    render() {
      const { run, steps } = this.state;
  
      return (
        <div id="tour">
          <Joyride
            continuous={true}
            run={run}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            steps={steps}
            autostart={true}
          />
        </div>
      )
    }
  }

export default (ReportTour);